<div class="notification-item" [ngClass]="{ 'notification-item--unread': data.new }">
  <div class="notification-item__logo">
    <img src="assets/gladiators-notification-logo.svg" alt="logo" lazyLoad />
  </div>

  <div class="notification-item__content">
    <div class="notification-item__title">{{ data.message }}</div>
    <div class="notification-item__message" *ngIf="data.subjectType !== NOTIFICATION_SUBJECT_TYPE.MOMENT">
      <a [routerLink]="link.link" (click)="closeDrawer()">
        {{ link.templateLink }}
      </a>
    </div>
  </div>

  <button class="close-btn icon-Close-Bold" (click)="notificationService.removeNotification(data.id)"></button>
</div>
