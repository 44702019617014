import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BUTTON_SIZE, BUTTON_TYPE, COLOSSEUM_TIERS } from '../enums';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Button, Response, SubscriptionInfo, TierPackages } from '../interfaces';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SubscriptionNotEnoughBalanceComponent } from '../components/modals/subscription-not-enough-balance/subscription-not-enough-balance.component';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  unlockedDays: bigint | number = 0;

  activeSubscription$ = new BehaviorSubject<SubscriptionInfo | null>(null);

  get activeSubscription() {
    return this.activeSubscription$.value;
  }

  get colosseumSubscriptionContract() {
    return environment.colosseumSubscriptionContract as `0x${string}`;
  }

  public tiersList = [COLOSSEUM_TIERS.BRONZE, COLOSSEUM_TIERS.SILVER, COLOSSEUM_TIERS.GOLD, COLOSSEUM_TIERS.PLATINUM];
  public tiersPrices$ = new BehaviorSubject<any | null>(null);

  public tiers$: Observable<TierPackages[]> = combineLatest([this.tiersPrices$, this.activeSubscription$]).pipe(
    map(([prices]) => {
      let tiers = [
        {
          type: COLOSSEUM_TIERS.BRONZE,
          name: 'Bronze',
          price: 'Free',
          free: true,
          priceDesciption: '',
          action: this.getButtonAction(COLOSSEUM_TIERS.BRONZE),
          buttonData: this.getButtonData(COLOSSEUM_TIERS.BRONZE),
          description: 'Sign up now and access essential features & benefits for FREE.'
        },
        {
          type: COLOSSEUM_TIERS.SILVER,
          saleFree: true,
          name: 'Silver',
          action: this.getButtonAction(COLOSSEUM_TIERS.SILVER),
          buttonData: this.getButtonData(COLOSSEUM_TIERS.SILVER),
          description:
            'Unlock Silver-Tier Experiences – View exclusive content, donate & earn GMRX tokens, and many more.'
        },
        {
          type: COLOSSEUM_TIERS.GOLD,
          name: 'Gold',
          action: this.getButtonAction(COLOSSEUM_TIERS.GOLD),
          buttonData: this.getButtonData(COLOSSEUM_TIERS.GOLD),
          description:
            'Unlock Gold-Tier Experiences - Exclusive content, GMRX rewards, 10% shop discount & other unique perks.'
          // disabled: true
        },
        {
          type: COLOSSEUM_TIERS.PLATINUM,
          name: 'Platinum',
          action: this.getButtonAction(COLOSSEUM_TIERS.PLATINUM),
          buttonData: this.getButtonData(COLOSSEUM_TIERS.PLATINUM),
          description:
            'Unlock Platinum-Tier Experiences – The Ultimate perk list, 25% shop discount, exclusive experiences, direct communication, and many more.'
          // disabled: true
        }
      ];

      if (prices) {
        tiers = tiers.map((tier) => {
          const price = prices[tier.type];

          if (price !== undefined) {
            tier.price = price;
          }
          return tier;
        });
      }

      return tiers;
    })
  );

  constructor(
    private toastrService: ToastrService,
    private dialogService: MatDialog,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.loadTiersPrices().subscribe();
  }

  private loadTiersPrices() {
    return this.httpClient
      .get<Response<{ tiersToPrices: any }>>(`${environment.gaiminApi}/colosseum-subscription/tiers`)
      .pipe(
        map((response) => {
          this.tiersPrices$.next(response.data?.tiersToPrices);
          return response.data?.tiersToPrices;
        })
      );
  }

  public loadActiveSubscription(): Observable<SubscriptionInfo> {
    return this.httpClient
      .get<Response<SubscriptionInfo>>(`${environment.gaiminApi}/colosseum-subscription/active`)
      .pipe(
        map((response) => {
          if (response.data?.tier && this.tiersList.includes(response.data.tier)) {
            this.activeSubscription$.next(response.data);
          }

          return this.activeSubscription$.value!;
        })
      );
  }

  public unsubscribe() {
    this.unsubscribeSubscriptionRequest()
      .pipe(
        tap((response) => {
          if (response.success) {
            window.location.reload();
            this.toastrService.success('Successfully unsubscribed');
          }
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe();
  }

  private unsubscribeSubscriptionRequest(): Observable<Response<any>> {
    return this.httpClient.post<Response<any>>(`${environment.gaiminApi}/colosseum-subscription/unsubscribe`, {});
  }

  private getButtonData(tier: COLOSSEUM_TIERS): Button {
    const upgradeButton: Button = {
      name: 'Upgrade',
      size: BUTTON_SIZE.MD
    };

    const unsubscribeButton = {
      name: 'Unsubscribe',
      size: BUTTON_SIZE.MD,
      type: BUTTON_TYPE.DARK_FILLED
    };

    const downgradeButton = {
      name: 'Downgrade',
      size: BUTTON_SIZE.MD
    };

    const startButton = {
      name: 'Start',
      size: BUTTON_SIZE.MD
    };

    const currentTier = this.activeSubscription$.value?.tier;

    if (!currentTier) {
      return startButton;
    }

    if (tier === currentTier) {
      return unsubscribeButton;
    }

    const tierIndex = this.tiersList.indexOf(tier);
    const currentTierIndex = this.tiersList.indexOf(currentTier);

    if (tierIndex < currentTierIndex) {
      return downgradeButton;
    }

    return upgradeButton;
  }

  private getButtonAction(tier: COLOSSEUM_TIERS): () => void {
    const currentTier = this.activeSubscription$.value?.tier;

    if (!currentTier) {
      return () => this.authService.login();
    }

    if (tier === currentTier || tier === COLOSSEUM_TIERS.BRONZE) {
      return () => this.unsubscribe();
    }

    return () => this.acquireSubscriptionTier(tier);
  }

  public acquireSubscriptionTier(tier: COLOSSEUM_TIERS): void {
    this.acquireUserSubscriptionRequest(tier).subscribe((response) => {
      if (response.success) {
        this.toastrService.success('Successfully subscribed');
        window.location.reload();
      } else {
        if (response.error?.type === 'INSUFFICIENT_BALANCE') {
          this.dialogService.open(SubscriptionNotEnoughBalanceComponent, {
            panelClass: ['dialog-overlay-pane']
          });
        }
      }
    });
  }

  private acquireUserSubscriptionRequest(tier: COLOSSEUM_TIERS): Observable<Response<SubscriptionInfo>> {
    return this.httpClient.post<Response<SubscriptionInfo>>(
      `${environment.gaiminApi}/colosseum-subscription/acquire?tier=${tier}`,
      {}
    );
  }

  public clearData() {
    this.activeSubscription$.next(null);
  }
}
