<button class="socials-login" (click)="login()">
  <div class="socials-login__item">
    <img src="assets/icons/social/google-icon.svg" alt="Google" /> {{ isRegisterForm ? 'Sign up' : 'Sign in' }} with
    Google
  </div>
  <div class="socials-login__item">
    <img src="assets/icons/social/discord-icon.svg" alt="Discord" /> {{ isRegisterForm ? 'Sign up' : 'Sign in' }} with
    Discord
  </div>
</button>
