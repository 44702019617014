import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { SHOW_LOADER } from 'src/app/core/interceptors/loader.interceptor';
import { LeaderBoardActivePeriodData, LeaderBoardItem, LeaderboardResponse, Response } from '../../interfaces';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {
  private leaderboardDataSrc = new BehaviorSubject<LeaderboardResponse | undefined>(undefined);
  leaderboardData$ = this.leaderboardDataSrc.asObservable();

  private meLeaderboardDataSrc = new BehaviorSubject<LeaderBoardItem | undefined>(undefined);
  meLeaderboardData$ = this.meLeaderboardDataSrc.asObservable();

  private leaderboardActivePeriodDataSrc = new BehaviorSubject<LeaderBoardActivePeriodData | undefined>(undefined);
  leaderboardActivePeriodData$ = this.leaderboardActivePeriodDataSrc.asObservable();

  constructor(private http: HttpClient) {}

  getLeaderboard(page = 1, shouldShowLoader = true, size = 10) {
    return this.http
      .get<Response<LeaderboardResponse>>(`${environment.gaiminApi}/leaderboard`, {
        params: {
          page,
          size
        },
        context: new HttpContext().set(SHOW_LOADER, shouldShowLoader)
      })
      .pipe(tap((data) => this.leaderboardDataSrc.next(data.data)));
  }

  getLeaderboardById(id: number) {
    return this.http
      .get<Response<LeaderBoardItem>>(`${environment.gaiminApi}/leaderboard/${id}`)
      .pipe(tap((data) => this.meLeaderboardDataSrc.next(data.data)));
  }

  getLeaderboardActivePeriod() {
    return this.http
      .get<Response<LeaderBoardActivePeriodData>>(`${environment.gaiminApi}/leaderboard/period`)
      .pipe(tap((data) => this.leaderboardActivePeriodDataSrc.next(data.data)));
  }
}
