<div class="package" *ngIf="tiers$ | async as tiers">
  <div class="grid">
    <div class="grid__header">
      <div class="grid__header-col hide-mobile"></div>
      <div>
        <app-package class="grid__header-col" [tier]="tiers[0]"></app-package>
        <ng-container *ngFor="let data of tableData; let i = index">
          <div class="grid__row hide-desktop" *ngIf="showAll || levelsVisibility.level0 || i < visibleFeaturesCount">
            <div class="grid__row-col">
              {{ data.name }}
              <span
                class="icon icon-tooltip icon-Question"
                [tooltip]="data.description"
                [tooltipPosition]="'left'"></span>
              <div class="icons-container">
                <span
                  class="icon"
                  [ngClass]="data.level0.available ? 'icon-Available active' : 'icon-Disabled'"
                  [tooltip]="data.level0.description"
                  [tooltipPosition]="'bottom'"></span>
                <span class="plus" *ngIf="data.level0.plus">+</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="show-more hide-desktop" *ngIf="!isLevelsVisible">
          <span (click)="toggleItemShowAll('level0')">
            {{ levelsVisibility.level0 ? 'View Less Features' : 'View More Features' }}
            <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: levelsVisibility.level0 }"></span>
          </span>
        </div>
      </div>
      <div>
        <app-package class="grid__header-col" [tier]="tiers[1]"></app-package>
        <ng-container *ngFor="let data of tableData; let i = index">
          <div class="grid__row hide-desktop" *ngIf="showAll || levelsVisibility.level1 || i < visibleFeaturesCount">
            <div class="grid__row-col">
              {{ data.name }}
              <span
                class="icon icon-tooltip icon-Question"
                [tooltip]="data.description"
                [tooltipPosition]="'left'"></span>
              <div class="icons-container">
                <span
                  class="icon"
                  [ngClass]="data.level1.available ? 'icon-Available active' : 'icon-Disabled'"
                  [tooltip]="data.level1.description"
                  [tooltipPosition]="'bottom'"></span>
                <span class="plus" *ngIf="data.level1.plus">+</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="show-more hide-desktop" *ngIf="!isLevelsVisible">
          <span (click)="toggleItemShowAll('level1')">
            {{ levelsVisibility.level1 ? 'View Less Features' : 'View More Features' }}
            <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: levelsVisibility.level1 }"></span>
          </span>
        </div>
      </div>
      <div>
        <app-package class="grid__header-col" [tier]="tiers[2]"></app-package>
        <ng-container *ngFor="let data of tableData; let i = index">
          <div class="grid__row hide-desktop" *ngIf="showAll || levelsVisibility.level2 || i < visibleFeaturesCount">
            <div class="grid__row-col">
              {{ data.name }}
              <span
                class="icon icon-tooltip icon-Question"
                [tooltip]="data.description"
                [tooltipPosition]="'left'"></span>
              <div class="icons-container">
                <span
                  class="icon"
                  [ngClass]="data.level2.available ? 'icon-Available active' : 'icon-Disabled'"
                  [tooltip]="data.level2.description"
                  [tooltipPosition]="'bottom'"></span>
                <span class="plus" *ngIf="data.level2.plus">+</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="show-more hide-desktop" *ngIf="!isLevelsVisible">
          <span (click)="toggleItemShowAll('level2')">
            {{ levelsVisibility.level2 ? 'View Less Features' : 'View More Features' }}
            <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: levelsVisibility.level2 }"></span>
          </span>
        </div>
      </div>
      <div>
        <app-package class="grid__header-col" [tier]="tiers[3]"></app-package>
        <ng-container *ngFor="let data of tableData; let i = index">
          <div class="grid__row hide-desktop" *ngIf="showAll || levelsVisibility.level3 || i < visibleFeaturesCount">
            <div class="grid__row-col">
              {{ data.name }}
              <span
                class="icon icon-tooltip icon-Question"
                [tooltip]="data.description"
                [tooltipPosition]="'left'"></span>
              <div class="icons-container">
                <span
                  class="icon"
                  [ngClass]="data.level3.available ? 'icon-Available active' : 'icon-Disabled'"
                  [tooltip]="data.level3.description"
                  [tooltipPosition]="'bottom'"></span>
                <span class="plus" *ngIf="data.level3.plus">+</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="show-more hide-desktop" *ngIf="!isLevelsVisible">
          <span (click)="toggleItemShowAll('level3')">
            {{ levelsVisibility.level3 ? 'View Less Features' : 'View More Features' }}
            <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: levelsVisibility.level3 }"></span>
          </span>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let data of tableData; let i = index">
      <div class="grid__row hide-mobile" *ngIf="showAll || i < visibleFeaturesCount">
        <div class="grid__row-col">
          {{ data.name }}
          <span class="icon icon-tooltip icon-Question" [tooltip]="data.description" [tooltipPosition]="'right'"></span>
        </div>
        <div class="grid__row-col">
          <div class="icons-container">
            <span
              class="icon"
              [ngClass]="data.level0.available ? 'icon-Available active' : 'icon-Disabled'"
              [tooltip]="data.level0.description"
              [tooltipPosition]="'bottom'"></span>
            <span class="plus" *ngIf="data.level0.plus">+</span>
          </div>
        </div>
        <div class="grid__row-col">
          <div class="icons-container">
            <span
              class="icon"
              [ngClass]="data.level1.available ? 'icon-Available active' : 'icon-Disabled'"
              [tooltip]="data.level1.description"
              [tooltipPosition]="'bottom'"></span>
            <span class="plus" *ngIf="data.level1.plus">+</span>
          </div>
        </div>
        <div class="grid__row-col">
          <div class="icons-container">
            <span
              class="icon"
              [ngClass]="data.level2.available ? 'icon-Available active' : 'icon-Disabled'"
              [tooltip]="data.level2.description"
              [tooltipPosition]="'bottom'"></span>
            <span class="plus" *ngIf="data.level2.plus">+</span>
          </div>
        </div>
        <div class="grid__row-col">
          <div class="icons-container">
            <span
              class="icon"
              [ngClass]="data.level3.available ? 'icon-Available active' : 'icon-Disabled'"
              [tooltip]="data.level3.description"
              [tooltipPosition]="'bottom'"></span>
            <span class="plus" *ngIf="data.level3.plus">+</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="package__show-more hide-mobile" *ngIf="!isLevelsVisible">
    <span (click)="toggleShowAll()">
      {{ showAll ? 'View Less Features' : 'View More Features' }}
      <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: showAll }"></span>
    </span>
  </div>
</div>

