<div class="header">
  <img
    *ngIf="!isHomePage"
    class="header__logo"
    src="../../../../assets/gladiators-logo.svg"
    alt="Gaimin Gladiators"
    (click)="openRoot()"
    lazyLoad />
  <ng-container *ngIf="isLoggedIn$ | async">
    <div
      class="header__item subscription"
      [ngClass]="{ 'hide-xs': isHomePage }"
      *ngIf="userSubscriptionData.type && userSubscriptionData.type !== COLOSSEUM_TIERS.BRONZE">
      <span class="icon"
        ><img class="tier" [src]="userSubscriptionData.img" [alt]="userSubscriptionData.name" lazyLoad
      /></span>
      <span class="text"> {{ userSubscriptionData.name }} </span>
      <span class="days">{{ userSubscriptionData.days }}</span>
    </div>

    <app-button
      class="header__btn"
      *ngIf="userSubscriptionData.type === COLOSSEUM_TIERS.BRONZE"
      (click)="becomeMember()"
      [buttonData]="becomeMemberBtn"></app-button>

    <app-button
      *ngIf="isHomePage && userService.isAccountActive()"
      class="header__btn"
      (click)="goToColosseum()"
      [buttonData]="goToColosseumBtn"></app-button>

    <div *ngIf="userService.userInfo$ | async" class="header__profile" clickOutside (clickOutside)="closeDropDown()">
      <img
        [src]="(userService.userInfo$ | async)?.avatarUrl || 'assets/icons/default-user-image.png'"
        onError="this.src = 'assets/icons/default-user-image.png'"
        (click)="openDropDown()"
        alt="Avatar"
        height="40"
        width="40"
        lazyLoad />
      <div *ngIf="isDropDownOpen" class="drop-down">
        <ul>
          <li (click)="openProfile()">My account</li>
          <li (click)="logout()">Sign out</li>
        </ul>
      </div>
    </div>
  </ng-container>

  <div *ngIf="(isLoggedIn$ | async) === false" class="header__btns">
    <app-button (click)="signIn()" [buttonData]="signInBtn"></app-button>
  </div>
</div>
