<div class="grid__col"  [ngClass]="{ disabled: tier.disabled }">
  <div class="grid__col__data">
    <h2>{{ tier.name }} <span *ngIf="tier.saleFree">(Free)</span></h2>
    <div class="grid__col__data-price">
      <span class="period" *ngIf="!tier.free">Hold at least</span>
      <div class="price__value">
        <span class="price" [ngClass]="{ line: tier.saleFree }">{{ (tier.price! | numberAbbreviation) ?? '-' }}</span>
        <span class="currency" *ngIf="!tier.free">GMRX</span>
      </div>
    </div>
    <p class="price-description" *ngIf="tier.priceDesciption">{{ tier.priceDesciption }}</p>
    <img [src]="'assets/icons/packages/' + tier.name.toLowerCase() + '.png'" [alt]="tier.name" lazyLoad />
    <app-button (click)="tier.action()" [buttonData]="tier.buttonData"></app-button>
  </div>

  <div class="grid__col__description">{{ tier.description }}</div>
</div>
