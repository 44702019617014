import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authStateService = inject(AuthStateService);
  const router = inject(Router);
  const dialogService = inject(MatDialog);

  return authStateService.isLoggedIn$.pipe(
    map((loggedIn) => handleAuthState(loggedIn, route, router)),
    catchError((err) => handleAuthError(err, router, dialogService))
  );
};

const handleAuthState = (loggedIn: boolean, route: ActivatedRouteSnapshot, router: Router): boolean => {
  if (loggedIn) {
    return true;
  }

  const utmSource = route.queryParams['utmSource'];
  const redirectUrl = utmSource ? `/?utmSource=${utmSource}` : '/';
  router.navigateByUrl(redirectUrl);
  return false;
};

const handleAuthError = (err: any, router: Router, dialogService: MatDialog) => {
  console.error('AuthGuard error:', err);
  router.navigateByUrl('/');
  dialogService.closeAll();
  return of(false);
};
