<div class="modal">
  <div class="header">
    <h2>Subscription management</h2>
    <button class="icon-Close close-icon" title="Close" (click)="close()"></button>
  </div>
  <mat-dialog-content class="modal-content">
    <h3 class="title">Not Enough Balance in Wallet</h3>
    <p class="description">
      It looks like you don't have enough balance in your wallet to upgrade your account. Please purchase more GMRX and
      try again.
    </p>

    <div class="buttons">
      <app-button
        [buttonData]="{
          name: 'Cancel',
          type: BUTTON_TYPE.GREY
        }" (click)="close()"></app-button>
      <app-button
        [buttonData]="{
          name: 'Buy GMRX'
        }" (click)="buy()"></app-button>
    </div>
  </mat-dialog-content>
</div>
