<mat-dialog-content class="{{ state$ | async }}">
  <button (click)="close()" class="close" ngClass="{{ state$ | async }}"><span class="icon icon-Close"></span></button>

  @if ((state$ | async) !== 'second') {
    <div class="content get-started" @fadeInOut>
      <p class="sub-title">Welcome to gaimin</p>
      <h1 class="title">Smart Wallet</h1>
      <p class="description">
        Receive, Store, and Send your digital assets with the GAIMIN Smart Wallet; your one-stop access to the Web3
        world. Crypto tokens, NFTs, and rewards all in one secure, easy-to-use wallet.
      </p>
      <button class="start__button" (click)="toggle()">Get Started</button>
    </div>
  }

  @if ((state$ | async) === 'second') {
    <form class="content sign-in" @fadeInOut [formGroup]="form" (ngSubmit)="submit()">
      <h2 class="title">Enter recovery password</h2>
      <p class="description">To access your GAIMIN Smart Wallet, please enter your Recovery Password</p>
      <password-input class="input" formControlName="password" [placeholder]="'Your password'" [showLabel]="false">
      </password-input>

      <div class="error" [class.visible]="form.get('password')?.touched">
        @if (form.get('password')?.hasError('incorrect')) {
          Provided recovery password is incorrect!
        } @else if (form.get('password')?.hasError('required')) {
          Please enter your password
        }
      </div>

      <button class="start__button" type="submit" [disabled]="form.invalid || (isLoading$ | async)">Confirm</button>
    </form>
  }

  <div class="illustration">
    <img class="robot" src="./assets/icons/smart-wallet/welcome/robot.png" />
    <img class="cage" src="./assets/icons/smart-wallet/welcome/bg-cage.svg" />
    @if ((state$ | async) !== 'second') {
      <img class="figures" @figuresFadeInOut src="./assets/icons/smart-wallet/welcome/bg.png" />
      <div class="cover" @fadeInOut></div>
    }
  </div>
</mat-dialog-content>
