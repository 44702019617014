import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { LazyLoadDirective } from '../../directives/lazy-load.directive';
import { TierPackages } from '../../interfaces';
import { NumberAbbreviationPipe } from '../../pipes/number-abbreviation.pipe';

@Component({
  selector: 'app-package',
  standalone: true,
  imports: [CommonModule, ButtonComponent, LazyLoadDirective, NumberAbbreviationPipe],
  templateUrl: './package.component.html',
  styleUrl: './package.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageComponent {
  @Input() tier!: TierPackages;
}
