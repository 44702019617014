<footer class="footer">
  <div class="home-wide-container">
    <img class="footer__logo" src="../../../../../assets/gladiators-logo.svg" alt="Gaimin Gladiators" (click)="openRoot()" lazyLoad />
    <span class="footer__description">
      "The Colosseum" is the Web 3 fan loyalty membership program of the
      <a href="https://www.gaimingladiators.gg/" target="_blank" rel="noopener noreferrer">Gaimin Gladiators</a>, a top esports gaming team
      founded in 2022 and powered by <a href="https://www.gaimin.gg/" target="_blank" rel="noopener noreferrer">Gaimin.gg</a>.
    </span>

    <div class="footer__contacts">
      <h3>Contact Us:</h3>
      <a href="mailto:colosseum@gaimin.io" target="_blank">colosseum&#64;gaimin.io</a>
    </div>

    <div class="footer__links">
      <h3>Follow Us</h3>
      <ul>
        <li><a href="https://discord.gg/gaimingladiators" target="_blank" rel="noopener noreferrer">Discord</a></li>
        <li><a href="https://www.facebook.com/GaiminGladiators/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
        <li><a href="https://twitter.com/GaiminGladiator" target="_blank" rel="noopener noreferrer">Twitter</a></li>
        <li><a href="https://www.instagram.com/gaimingladiators/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
        <li><a href="https://www.youtube.com/@GaiminGladiators" target="_blank" rel="noopener noreferrer">Youtube</a></li>
        <li><a href="https://www.tiktok.com/@gaimingladiators" target="_blank" rel="noopener noreferrer">Tiktok</a></li>
      </ul>
    </div>
    <div class="footer__terms">
      <span class="footer__terms-text">Copyright © {{ currentYear }} Gaimin Gladiators. All rights reserved.</span>
      <div class="footer__terms-links">
        <a href="https://legal.gaimin.gg/end-user-licence-agreement-eula-colosseum" target="_blank">End User License Agreement</a>
        <a href="https://legal.gaimin.gg/privacy-policy-colosseum" target="_blank">Privacy Policy</a>
      </div>
    </div>
  </div>
</footer>
