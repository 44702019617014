<header class="home-header" *ngIf="loaded" @fadeInOut>
  <app-email-confirmation></app-email-confirmation>
  <div class="home-wide-container">
    <div class="home-header__menu-mobile" (click)="openSidebar()">
      <span class="icon-Menu"></span>
    </div>
    <ul class="home-header__menu">
      <li>
        <button (click)="goToPackages()">Packages</button>
      </li>
      <li>
        <button (click)="goToFaqs()">FAQ</button>
      </li>
      <li>
        <button (click)="goToBlog()">Blog</button>
      </li>
    </ul>
    <a [routerLink]="'/'">
      <img class="home-header__logo" src="../../../../../assets/gladiators-logo.svg" alt="Gaimin Gladiators" lazyLoad />
    </a>
    <div *ngIf="!(authService.isLoggedIn$ | async)" class="home-header__btns">
      <app-button (click)="signIn()" [buttonData]="signInBtn"></app-button>
      <app-button (click)="becomeMember()" [buttonData]="memberBtn"></app-button>
    </div>
    <app-header-profile
      *ngIf="authService.isLoggedIn$ | async"
      class="header__profile"
      [isHomePage]="true"></app-header-profile>
  </div>
</header>

<div
  class="home-sidebar__wrapper"
  *ngIf="loaded"
  @fadeInOut
  [ngClass]="{ active: isSideBarOpen$ | async }"
  (click)="closeSidebar()"
  cdkTrapFocus
  cdkTrapFocusAutoCapture>
  <div class="home-sidebar" [ngClass]="{ active: isSideBarOpen$ | async }" (click)="stopPropagation($event)">
    <button class="close-btn icon-Close-Bold" (click)="closeSidebar()"></button>
    <a class="home-sidebar__header" [routerLink]="'/'">
      <img src="../../../../assets/gladiators-logo.svg" alt="Gaimin Gladiators" lazyLoad />
    </a>
    <ul class="home-sidebar__menu">
      <li>
        <button (click)="goToPackages()">Packages <span class="icon-Arrow-Right"></span></button>
      </li>
      <li>
        <button (click)="goToFaqs()">FAQ <span class="icon-Arrow-Right"></span></button>
      </li>
      <li>
        <button (click)="goToBlog()">Blog <span class="icon-Arrow-Right"></span></button>
      </li>
    </ul>
    <div class="home-sidebar__btns" *ngIf="!(authService.isLoggedIn$ | async)">
      <app-button (click)="signIn()" [buttonData]="signInBtn"></app-button>
      <app-button (click)="becomeMember()" [buttonData]="memberBtn"></app-button>
    </div>
  </div>
</div>
