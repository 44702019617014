import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberAbbreviation',
  standalone: true
})
export class NumberAbbreviationPipe implements PipeTransform {
  transform(value: number | string): string | number {
    const numberValue = +value;

    if (!numberValue || isNaN(numberValue)) {
      return value;
    }

    const suffixes = ['', 'K', 'M', 'B', 'T', 'P', 'E'];
    const tier = Math.floor(Math.log10(Math.abs(numberValue)) / 3);

    if (tier === 0) return value.toString();

    const suffix = suffixes[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = numberValue / scale;

    return scaled.toFixed(1).replace(/\.0$/, '') + suffix;
  }
}
