import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { AdditionalService } from '../../../services/additional.service';
import { UserService } from '../../../services/user.service';
import { BUTTON_SIZE, USER_ROLES } from '../../../enums';
import { UserSignUpCredentials, Response } from '../../../interfaces';
import { ButtonComponent } from '../../button/button.component';
import { SocialsLoginComponent } from '../../socials-login/socials-login.component';
import { LoaderComponent } from '../../loader/loader.component';
import { LazyLoadDirective } from 'src/app/shared/directives/lazy-load.directive';
import { PasswordInputComponent } from '../../password-input/password-input.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    SocialsLoginComponent,
    LoaderComponent,
    LazyLoadDirective,
    PasswordInputComponent,
    MatCheckboxModule,
    RouterModule
  ],
  standalone: true
})
export class LoginModalComponent implements OnInit {
  private readonly EMAIL_REGEX: string = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}';
  readonly BUTTON_SIZE: typeof BUTTON_SIZE = BUTTON_SIZE;
  emailMinLength = 3;
  emailMaxLength = 500;
  passwordMinLength = 8;
  passwordMaxLength = 50;
  nameMinLength = 2;
  nameMaxLength = 50;
  isRegister: boolean = this.data.isSignUp;

  loginForm: FormGroup = new FormGroup<any>({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  registerForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(this.emailMinLength),
      Validators.maxLength(this.emailMaxLength),
      Validators.pattern(this.EMAIL_REGEX)
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(this.passwordMinLength),
      Validators.maxLength(this.passwordMaxLength),
      Validators.pattern('^(?!\\s)(?!.*\\s$)(?!.*?(\\s)).*$'),
      this.additionalService.regexValidator(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\W]).*$'), {
        complexity: true
      })
    ]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z- ]*$'),
      this.additionalService.regexValidatorV2(/\s{2,}/, { complexity: true })
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z- ]*$'),
      this.additionalService.regexValidatorV2(/\s{2,}/, { complexity: true })
    ]),
    tos: new FormControl(false, [Validators.requiredTrue]),
    privacyPolicy: new FormControl(false, [Validators.requiredTrue]),
    mailingList: new FormControl(false, [])
  });

  constructor(
    private location: Location,
    private dialogRef: MatDialogRef<LoginModalComponent>,
    private userService: UserService,
    public additionalService: AdditionalService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA)
    public data: { isSignUp: boolean; isFullScreen: boolean }
  ) {}

  ngOnInit(): void {
    this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          this.closeModal();
        }
      }
    });
  }

  toggleFormType() {
    this.isRegister = !this.isRegister;
    this.loginForm.reset();
    this.registerForm.reset();
  }

  performLoginRequest() {
    if (this.loginForm.invalid) return;

    this.authService.sendLoginRequest(this.loginForm.value).subscribe({
      next: (response) => {
        this.handleResponse(response, this.loginForm.value);
      }
    });
  }

  performRegisterRequest() {
    if (this.registerForm.invalid) return;

    this.authService.sendRegisterRequest(this.registerForm.value as UserSignUpCredentials).subscribe({
      next: (response) => {
        this.handleResponse(response, this.registerForm.value);
        this.authService.checkUtmLink();
      }
    });
  }

  onSubmit() {
    this.isRegister ? this.performRegisterRequest() : this.performLoginRequest();
  }

  handleResponse(response: Response<any>, formValue: typeof this.registerForm.value) {
    if (response.success && response.data) {
      const shouldRedirect = !this.data.isFullScreen;
      const showOnboardingPopup = response.data.isEmptyProfileInfo;
      const loginData = response.data.token || response.data;
      this.authService.successAuthorize(loginData, showOnboardingPopup, shouldRedirect);
      this.authService.checkDpaEventEnrollment(true, false);
      this.updateUserRoles(formValue);

      if (formValue.mailingList) {
        this.userService.addToMailingList().subscribe();
      }
    }
  }

  updateUserRoles(formValue: typeof this.registerForm.value) {
    this.authService.getUserPermissions(formValue).subscribe({
      next: (userRolesResponse) => {
        let userRole = USER_ROLES.ROLE_USER;
        if (userRolesResponse.success) {
          const currentUserRoles = userRolesResponse.data;
          this.authService.currentUserRoles$.next(currentUserRoles!);
          if (this.authService.checkUserRights([USER_ROLES.ROLE_COLOSSEUM_MANAGER])) {
            userRole = USER_ROLES.ROLE_COLOSSEUM_MANAGER;
          }
        } else {
          this.authService.currentUserRoles$.next([userRole]);
        }
        this.authService.userRoles = userRole;
      }
    });
  }

  closeModal() {
    if (!this.data.isFullScreen) {
      this.location.replaceState('/');
    }
    this.dialogRef.close();
  }
}
