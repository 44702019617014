export interface MoralisResponse<T> {
  cursor: string | null;
  limit: string;
  page: number;
  page_size: number;
  result: T;
}

export interface MoralisTokenResponse {
  balance: string;
  decimals: number;
  logo: string | null;
  name: string;
  percentageRelativeToTotalSupply: number;
  possibleSpam: boolean;
  securityScore: string | null;
  symbol: string;
  thumbnail: string | null;
  tokenAddress: string;
  totalSupply: string;
  totalSupplyFormatted: string;
  verifiedContract: boolean;
}

export interface MoralisNFTResponse {
  amount: string;
  tokenId: string;
  tokenAddress: string;
  contractType: TOKEN_STANDARD.ERC1155 | TOKEN_STANDARD.ERC721;
  ownerOf: string;
  lastMetadataSync: string;
  lastTokenUriSync: string;
  metadata: string;
  blockNumber: string;
  blockNumberMinted: string;
  name: string;
  symbol: string;
  tokenHash: string;
  tokenUri: string;
  minterAddress: string;
  rarityRank: number;
  rarityPercentage: number;
  rarityLabel: string;
  verifiedCollection: boolean;
  possibleSpam: boolean;
  collectionLogo: string;
  collectionBannerImage: string;
  floorPrice: string;
  floorPriceUsd: string;
  floorPriceCurrency: string;
}

export interface MoralisTransactionResponse {
  hash: string;
  nonce: string;
  transactionIndex: string;
  fromAddressEntity: string;
  fromAddressEntityLogo: string;
  fromAddress: string;
  fromAddressLabel: string;
  toAddressEntity: string;
  toAddressEntityLogo: string;
  toAddress: string;
  toAddressLabel: string;
  value: string;
  gas: string;
  gasPrice: string;
  receiptCumulativeGasUsed: string;
  receiptGasUsed: string;
  receiptContractAddress: string;
  receiptStatus: string;
  blockTimestamp: string;
  blockNumber: string;
  blockHash: string;
  transactionFee: string;
  methodLabel: string;
  nftTransfers: [
    {
      logIndex: number;
      value: string;
      contractType: string;
      transactionType: string;
      tokenAddress: string;
      tokenId: string;
      fromAddressEntity: string;
      fromAddressEntityLogo: string;
      fromAddress: string;
      fromAddressLabel: string;
      toAddressEntity: string;
      toAddressEntityLogo: string;
      toAddress: string;
      toAddressLabel: string;
      amount: string;
      operator: string;
      possibleSpam: boolean;
      verifiedCollection: boolean;
      direction: string;
    }
  ];
  erc20Transfers: [
    {
      tokenName: string;
      tokenSymbol: string;
      tokenLogo: string;
      tokenDecimals: string;
      fromAddressEntity: string;
      fromAddressEntityLogo: string;
      fromAddress: string;
      fromAddressLabel: string;
      toAddressEntity: string;
      toAddressEntityLogo: string;
      toAddress: string;
      toAddressLabel: string;
      address: string;
      logIndex: number;
      value: string;
      possibleSpam: boolean;
      verifiedContract: boolean;
      securityScore: string;
      direction: string;
      valueFormatted: string;
    }
  ];
  nativeTransfers: [
    {
      fromAddressEntity: string;
      fromAddressEntityLogo: string;
      fromAddress: string;
      fromAddressLabel: string;
      toAddressEntity: string;
      toAddressEntityLogo: string;
      toAddress: string;
      toAddressLabel: string;
      value: string;
      valueFormatted: string;
      direction: string;
      internalTransaction: boolean;
      tokenSymbol: string;
      tokenLogo: string;
    }
  ];
  summary: string;
  possibleSpam: boolean;
  category: TRANSACTION_TYPE;
}

export enum TRANSACTION_TYPE {
  TOKEN_RECEIVE = 'token receive',
  TOKEN_SEND = 'token send',
  TOKEN_SWAP = 'token swap',
  APPROVE = 'approve',
  RECEIVE = 'receive',
  SEND = 'send',
  CONTRACT_INTERACTION = 'contract interaction',
  AIRDROP = 'airdrop',
  MINT = 'mint',
  NFT_SEND = 'nft send',
  NFT_RECEIVE = 'nft receive',
  NFT_PURCHASE = 'nft purchase'
}

export interface Token {
  contractAddress: string;
  name: string;
  symbol: string;
  balance: number;
  decimals: number;
  logo?: string | null;
  native?: boolean;
}

export interface TokenWithPrice extends Token {
  usd: {
    usdPrice: number;
    usdPriceDisplay: string;
    usdTotal: number;
    usdTotalDisplay: string;
  };
}

export interface ExchangeRateToken {
  currencyCode: string;
  tokenAddress?: string;
}

export interface ExchangeRate {
  [tokenSymbol: string]: number;
}

export interface NFT {
  amount: string;
  tokenId: string;
  name: string;
  metadata: {
    image: string;
    symbol: string;
    description: string;
    name: string;
  };
  tokenAddress: string;
  tokenHash: string;
  symbol: string;
  imageUrl: string;
  contractType: TOKEN_STANDARD.ERC1155 | TOKEN_STANDARD.ERC721;
  contractAddress: string;
}

export interface Transaction {
  asset: {
    name: string;
    logo: string | null;
    amount: number;
  };
  direction: string;
  category: TRANSACTION_TYPE;
  type: string;
  blockStamp: {
    date: string;
    time: string;
  };
  usd: number;
  network: string;
  fee: number;
  address: string;
  txid: string;
  success: boolean;
}

export interface TransactionData {
  toAddress: string;
  value: string;
  contract?: string;
  tokenId?: string;
  tokenStandard: TOKEN_STANDARD;
}

export enum TOKEN_STANDARD {
  NATIVE = 'NATIVE',
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155'
}

export interface TransactionResponse {
  id: string;
  createdAt: number;
  object: string;
  details: {
    userOperationHash: string;
    userOperation: {
      sender: string;
      nonce: string;
      initCode: string;
      callData: string;
      callGasLimit: string;
      verificationGasLimit: string;
      preVerificationGas: string;
      maxFeePerGas: string;
      maxPriorityFeePerGas: string;
      paymasterAndData: string;
      signature: string;
    };
  };
  chainId: number;
  abstractionType: string;
  updatedAt: number;
  policy: {
    id: string;
  };
  player: {
    id: string;
  };
  account: {
    id: string;
  };
  response: {
    createdAt: number;
    logs: [
      {
        removed: true;
        transactionIndex: number;
        blockNumber: number;
        transactionHash: string;
        address: string;
        topics: string[];
        data: string;
        logIndex: number;
        blockHash: string;
      }
    ];
    blockNumber: number;
    transactionHash: string;
    to: string;
    gasUsed: string;
    status: number;
  };
  interactions: [
    {
      functionName: string;
      contract: string;
      functionArgs: string[];
    }
  ];
}

export interface SmartWalletSettings {
  isPrivateMode: boolean;
  wasSigned: boolean;
  agreedToSendTerms: boolean;
  selectedCurrency: string;
}

export interface Estimate {
  success: boolean;
  errorResponse: string | null;
  preVerificationGas: string;
  verificationGas: string;
  callGasLimit: string;
  verificationGasLimit: string | null;
  estimatedTXGas: string;
  estimatedTXGasFee: string;
  estimatedTXGasFeeUSD: string;
  estimatedTXGasFeeToken: string;
  gasPrice: string;
}
