import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'message-modal',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './message-modal.component.html',
  styleUrl: './message-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageModalComponent {
  safeMessage: SafeHtml;

  constructor(
    private dialogRef: MatDialogRef<MessageModalComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string }
  ) {
    this.safeMessage = this.sanitizer.bypassSecurityTrustHtml(data.message);
  }

  close() {
    this.dialogRef.close();
  }
}
