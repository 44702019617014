<div class="modal">
  <div class="header">
    @if (data.title) {
      <h2>{{ data.title }}</h2>
    }
    <button class="icon-Close close-icon" title="Close" (click)="close()"></button>
  </div>
  <mat-dialog-content class="modal-content">
    @if (data.message) {
      <p [innerHTML]="safeMessage"></p>
    }
  </mat-dialog-content>
</div>
