import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { BehaviorSubject, filter, interval, startWith, Subscription, switchMap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AdditionalService } from './shared/services/additional.service';
import { AuthService } from './shared/services/auth.service';
import { NotificationService } from './shared/services/notification.service';
import { NotificationToastComponent } from './shared/components/notification/notification-toast/notification-toast.component';
import { AnalyticsService } from './shared/services/analytics.service';
import { ScrollService } from './shared/services/scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isHomePage = new BehaviorSubject<boolean | null>(null);

  constructor(
    private activatedRoute: ActivatedRoute,
    public additionalService: AdditionalService,
    private authService: AuthService, // do not remove, should be injected,
    private readonly toastrService: ToastrService,
    private readonly notificationService: NotificationService,
    private readonly analyticsService: AnalyticsService,
    private readonly scrollService: ScrollService,
    private readonly router: Router
  ) {

  }

  @AutoUnsubscribe()
  private timeIntervalSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private isHomePageSub: Subscription = {} as Subscription;

  isLoggedIn = this.authService.isLoggedIn$;

  shownNotificationIds: number[] = [];
  currentUrl: string = '';

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd || event instanceof NavigationStart)
    ).subscribe((value: any) => {
      const isNavigationEnd = value instanceof NavigationEnd;

      let currentRoute = this.activatedRoute;
      let isHomePageLayout = false;

      while (currentRoute.firstChild) {
        currentRoute = currentRoute.firstChild;
        const { layout } = currentRoute.snapshot.data;

        isHomePageLayout = layout === 'landing';
      }

      this.isHomePage.next(isHomePageLayout);

      if (this.currentUrl ) {
        if (this.additionalService.backToUrl === value.url) {
          this.additionalService.backToUrls.pop();
        } else {
          this.additionalService.backToUrls.push(this.currentUrl);
        }
      }


      if (isNavigationEnd) {
        this.currentUrl = value.urlAfterRedirects.split('?')[0];
      }
    });

    this.analyticsService.loadGoogleTagManager();

    this.activatedRoute.queryParams.subscribe((params) => {
      const utmSource = params['utmSource'];
      const invitingUserId = params['invitingUserId'];
      if (utmSource) {
        this.authService.utmSourceId = utmSource;
      }

      if (invitingUserId) {
        this.authService.invitingUserId = invitingUserId;
        this.authService.openLoginPopup(true);
      }

      if (params['scrollTo']) {
        this.scrollService.scrollTo(params['scrollTo']);
      }
    });

    this.isHomePageSub = this.isHomePage.subscribe((isHomePageTemplate) => {
      if (this.authService.isLoggedIn$.getValue() && !isHomePageTemplate && isHomePageTemplate !== null) {
        this.timeIntervalSub = interval(60 * 1000)
          .pipe(
            startWith(0),
            switchMap(() => this.notificationService.getNotifications())
          )
          .subscribe((data) => {
            data?.forEach((item) => {
              const isNotShown = !this.shownNotificationIds.includes(item.id);

              if (item.new && isNotShown && this.shownNotificationIds.length < 5) {
                this.toastrService.info(undefined, undefined, {
                  toastClass: 'toast-notification ngx-toastr',
                  toastComponent: NotificationToastComponent,
                  payload: item
                });

                this.shownNotificationIds.push(item.id);
              }
            });
          });
      }
    });
  }
}
