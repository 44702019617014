import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '../../button/button.component';
import { BUTTON_TYPE } from 'src/app/shared/enums';
import { MeldModalComponent } from '../meld-modal/meld-modal.component';
import { UserService } from 'src/app/shared/services/user.service';
import { filter, take } from 'rxjs';

@Component({
  selector: 'subscription-not-enough-balance',
  standalone: true,
  imports: [MatDialogModule, ButtonComponent],
  templateUrl: './subscription-not-enough-balance.component.html',
  styleUrl: './subscription-not-enough-balance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionNotEnoughBalanceComponent {
  constructor(
    private dialogRef: MatDialogRef<SubscriptionNotEnoughBalanceComponent>,
    private dialog: MatDialog,
    private userService: UserService
  ) {}

  BUTTON_TYPE = BUTTON_TYPE;

  buy() {
    this.close();
    this.userService.userWallet$.pipe(filter(Boolean), take(1)).subscribe((address) => {
      this.dialog.open(MeldModalComponent, {
        autoFocus: false,
        data: { address },
        panelClass: 'meld-modal-panel'
      });
    });
  }

  close() {
    this.dialogRef.close();
  }
}
