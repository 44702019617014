<div class="buy-gmrx">
  <div class="header">
    <h2>Buy GMRX</h2>
    <button class="icon-Close close-icon" title="Close" (click)="closeModal()"></button>
  </div>

  <div class="modal-content">
    <div class="buy-gmrx__list">
      <div
        class="buy-gmrx__list-item wallet"
        *ngIf="data === EXCHANGE_CURRENCY.GMRX && (walletConnectService.walletGmrxBalance$ | async) !== null"
      >
        <span class="icon-btn">
          <img src="assets/icons/coins/gmrx.png" alt="GMRX" lazyLoad />
        </span>
        <span class="balance text">
          <span class="value">
            {{ walletConnectService.walletGmrxBalance$ | async }}
          </span>
          <span *ngIf="(walletConnectService.walletGmrxUSDBalance$ | async) !== null">
            (${{ walletConnectService.walletGmrxUSDBalance$ | async | number: '1.2-2' }})
          </span></span
        >
      </div>

      <div
        class="buy-gmrx__list-item wallet"
        *ngIf="data === EXCHANGE_CURRENCY.BNB && (walletConnectService.walletBnbBalance$ | async) !== null"
      >
        <span class="icon-btn">
          <img src="assets/icons/coins/bnb.svg" alt="BNB" lazyLoad />
        </span>
        <span class="balance text">
          <span class="value"> {{ walletConnectService.walletBnbBalance$ | async | number: '1.5-5' }}</span>
          <span *ngIf="(walletConnectService.walletBnbUSDBalance$ | async) !== null">
            (${{ walletConnectService.walletBnbUSDBalance$ | async | number: '1.2-2' }})
          </span>
        </span>
      </div>

      <a
        *ngFor="let item of dataList"
        class="buy-gmrx__list-item"
        [ngClass]="{ 'hide-mobile': data && data === EXCHANGE_CURRENCY.BNB }"
        [href]="item.link"
        target="_blank"
      >
        <span class="text">{{ item.name }}</span>
        <img [src]="item.imageUrl" [alt]="item.name" lazyLoad />
      </a>
      <div class="buy-gmrx__list-item disabled creditcard">
        <span class="text">Credit card</span>
        <span class="icon icon-Credit-Card"></span>
        <span class="coming-soon">Coming soon</span>
      </div>
      <div class="buy-gmrx__list-item wallet" *ngIf="data && (walletConnectService.isWalletConnected$ | async)">
        <span class="text code">{{ walletConnectService.walletAddress | cutWalletAddress }}</span>
        <span class="icon icon-Copy" (click)="onCopy(walletConnectService.walletAddress!)"></span>
      </div>
    </div>
  </div>
</div>
