import { MoralisTransactionResponse, Transaction, TRANSACTION_TYPE } from './smart-wallet.model';

export const getTransactionAsset = (
  transaction: MoralisTransactionResponse,
  transactionCategory: TRANSACTION_TYPE
): Transaction['asset'] => {
  const erc20Transfer = transaction.erc20Transfers[0];
  const nativeTransfer = transaction.nativeTransfers[0];
  const nftTransfer = transaction.nftTransfers[0];

  switch (transactionCategory) {
    case TRANSACTION_TYPE.TOKEN_RECEIVE:
    case TRANSACTION_TYPE.TOKEN_SEND:
    case TRANSACTION_TYPE.TOKEN_SWAP:
      const transfer = erc20Transfer || nativeTransfer;
      return {
        name: transfer.tokenSymbol,
        logo: transfer.tokenLogo,
        amount: Number(transfer.valueFormatted)
      };

    case TRANSACTION_TYPE.AIRDROP:
      if (erc20Transfer) {
        return {
          name: erc20Transfer.tokenSymbol,
          logo: erc20Transfer.tokenLogo,
          amount: Number(erc20Transfer.valueFormatted)
        };
      } else {
        return {
          name: nftTransfer.fromAddressEntity || nftTransfer.tokenId,
          logo: nftTransfer.fromAddressEntityLogo,
          amount: Number(nftTransfer.amount)
        };
      }

    case TRANSACTION_TYPE.SEND:
    case TRANSACTION_TYPE.RECEIVE:
      return {
        name: nativeTransfer.tokenSymbol,
        logo: nativeTransfer.tokenLogo,
        amount: Number(nativeTransfer.valueFormatted)
      };

    case TRANSACTION_TYPE.MINT:
    case TRANSACTION_TYPE.NFT_RECEIVE:
    case TRANSACTION_TYPE.NFT_SEND:
    case TRANSACTION_TYPE.NFT_PURCHASE:
      return {
        name: nftTransfer.tokenAddress,
        logo: nftTransfer.fromAddressEntityLogo || 'assets/icons/smart-wallet/nft-placeholder.png',
        amount: Number(nftTransfer.amount)
      };

    default:
      return { name: transaction.summary, logo: '', amount: 0 };
  }
};

export const getTransactionAddress = (
  transaction: MoralisTransactionResponse,
  transactionCategory: TRANSACTION_TYPE
): Transaction['address'] => {
  const erc20Transfer = transaction.erc20Transfers[0];
  const nativeTransfer = transaction.nativeTransfers[0];
  const nftTransfer = transaction.nftTransfers[0];

  switch (transactionCategory) {
    case TRANSACTION_TYPE.TOKEN_RECEIVE:
    case TRANSACTION_TYPE.RECEIVE:
      return erc20Transfer?.fromAddress || nativeTransfer?.fromAddress;

    case TRANSACTION_TYPE.TOKEN_SEND:
    case TRANSACTION_TYPE.SEND:
    case TRANSACTION_TYPE.TOKEN_SWAP:
      return erc20Transfer?.toAddress || nativeTransfer?.toAddress;

    case TRANSACTION_TYPE.AIRDROP:
      if (erc20Transfer) {
        return erc20Transfer.fromAddress;
      } else {
        return nftTransfer?.fromAddress;
      }

    case TRANSACTION_TYPE.MINT:
    case TRANSACTION_TYPE.NFT_RECEIVE:
    case TRANSACTION_TYPE.NFT_PURCHASE:
      return nftTransfer?.fromAddress;

    case TRANSACTION_TYPE.NFT_SEND:
      return nftTransfer?.toAddress;

    default:
      return transaction.fromAddress;
  }
};

export const getTransactionDirection = (transactionCategory: TRANSACTION_TYPE): string => {
  const sendTypes = [
    TRANSACTION_TYPE.NFT_SEND,
    TRANSACTION_TYPE.TOKEN_SWAP,
    TRANSACTION_TYPE.TOKEN_SEND,
    TRANSACTION_TYPE.SEND
  ];

  if (sendTypes.includes(transactionCategory)) {
    return '-';
  }

  return '+';
};

export const transformTransactionType = (transactionCategory: TRANSACTION_TYPE): string => {
  const types = {
    [TRANSACTION_TYPE.TOKEN_RECEIVE]: 'Receive',
    [TRANSACTION_TYPE.TOKEN_SEND]: 'Send',
    [TRANSACTION_TYPE.TOKEN_SWAP]: 'Swap',
    [TRANSACTION_TYPE.APPROVE]: 'Approve',
    [TRANSACTION_TYPE.RECEIVE]: 'Receive',
    [TRANSACTION_TYPE.SEND]: 'Send',
    [TRANSACTION_TYPE.CONTRACT_INTERACTION]: 'Contract interaction',
    [TRANSACTION_TYPE.AIRDROP]: 'Airdrop',
    [TRANSACTION_TYPE.MINT]: 'Mint',
    [TRANSACTION_TYPE.NFT_SEND]: 'Send NFT',
    [TRANSACTION_TYPE.NFT_RECEIVE]: 'Receive NFT',
    [TRANSACTION_TYPE.NFT_PURCHASE]: 'Purchase NFT'
  };

  return types[transactionCategory];
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return {
    date: `${year}-${month}-${day}`,
    time: `${hours}:${minutes}`
  };
};
