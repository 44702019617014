import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { NftMarketPageComponent } from './pages/nft-market-page/nft-market-page.component';
import { AuctionPageComponent } from './pages/auction-page/auction-page.component';
import { CharityPageComponent } from './pages/charity-page/charity-page.component';

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LazyLoadDirective } from './shared/directives/lazy-load.directive';
import { HomeFooterComponent } from './pages/home-page/home-footer/home-footer.component';
import { HomeHeaderComponent } from './pages/home-page/home-header/home-header.component';
import { ButtonComponent } from './shared/components/button/button.component';
import { FaqsGuidesComponent } from './shared/components/faqs-guides/faqs-guides.component';
import { FaqsQueriesComponent } from './shared/components/faqs-queries/faqs-queries.component';
import { PackagesComponent } from './shared/components/packages/packages.component';
import { TickerComponent } from './shared/components/ticker/ticker.component';
import { YoutubeComponent } from './shared/components/youtube/youtube.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { CoreModule } from './core/core.module';

@NgModule({
  declarations: [
    AppComponent,
    RewardsPageComponent,
    NftMarketPageComponent,
    AuctionPageComponent,
    CharityPageComponent,
    HomePageComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    CoreModule,
    AppRoutingModule,
    NgOptimizedImage,

    HeaderComponent,
    LoaderComponent,
    HomeHeaderComponent,
    ButtonComponent,
    YoutubeComponent,
    PackagesComponent,
    FaqsQueriesComponent,
    FaqsGuidesComponent,
    TickerComponent,
    HomeFooterComponent,
    LazyLoadDirective
  ]
})
export class AppModule {}
