import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { BuyGmrxModalComponent } from '../modals/buy-gmrx-modal/buy-gmrx-modal.component';
import { WalletConnectService } from '../../services/wallet-connect.service';
import { Button, SubscriptionTypeData } from '../../interfaces';
import { BUTTON_SIZE, BUTTON_TYPE, COLOSSEUM_TIERS, EXCHANGE_CURRENCY } from '../../enums';
import { ButtonComponent } from '../button/button.component';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { LazyLoadDirective } from '../../directives/lazy-load.directive';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
  imports: [ButtonComponent, CommonModule, ClickOutsideDirective, LazyLoadDirective],
  standalone: true
})
export class HeaderProfileComponent implements OnInit {
  @Input() isHomePage: boolean = false;

  protected readonly COLOSSEUM_TIERS = COLOSSEUM_TIERS;
  protected readonly EXCHANGE_CURRENCY = EXCHANGE_CURRENCY;

  userSubscriptionData: SubscriptionTypeData = {} as SubscriptionTypeData;
  isDropDownOpen: boolean = false;
  isOpenBuyGMRXModal: boolean = false;

  readonly buyGMRXBtnDesktop: Button = {
    name: 'BUY GMRX',
    size: BUTTON_SIZE.MD
  };

  readonly buyGMRXBtnMobile: Button = {
    img: 'coins/gmrx.png',
    type: BUTTON_TYPE.IMAGE,
    size: BUTTON_SIZE.MD
  };

  readonly becomeMemberBtn: Button = {
    name: 'Become a member',
    size: BUTTON_SIZE.MD
  };

  readonly goToColosseumBtn: Button = {
    name: 'The Colosseum',
    size: BUTTON_SIZE.MD
  };

  readonly signInBtn: Button = {
    name: 'Sign in',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.DARK
  };

  get currencies(): EXCHANGE_CURRENCY[] {
    return Object.values(EXCHANGE_CURRENCY);
  }

  constructor(
    private router: Router,
    private dialogService: MatDialog,
    private toastrService: ToastrService,
    public authService: AuthService,
    public walletConnectService: WalletConnectService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.userSubscriptionData$.subscribe({
      next: (response) => {
        this.userSubscriptionData = response;
      }
    });

    const combinedBalances$ = combineLatest([
      this.walletConnectService.walletGmrxBalance$,
      this.walletConnectService.walletBnbBalance$
    ]).pipe(skip(1));

    combinedBalances$
      .pipe(filter(([gmrxBalance, bnbBalance]) => gmrxBalance !== null && bnbBalance !== null))
      .subscribe(([gmrxBalance, bnbBalance]) => {
        this.userService.getExchangeRateRequest(this.currencies).subscribe({
          next: (response) => {
            if (response.data && response.data.rates) {
              if (response.data.rates[EXCHANGE_CURRENCY.GMRX]) {
                this.walletConnectService.walletGmrxUSDBalance$.next(gmrxBalance! * response.data.rates['GMRX']);
                if (this.isOpenBuyGMRXModal) {
                  this.buyGMRX(EXCHANGE_CURRENCY.GMRX);
                }
              }
              if (response.data.rates[EXCHANGE_CURRENCY.BNB]) {
                this.walletConnectService.walletBnbUSDBalance$.next(bnbBalance! * response.data.rates['BNB']);
              }
            }
          }
        });
      });
  }

  openDropDown() {
    this.isDropDownOpen = !this.isDropDownOpen;
  }

  closeDropDown() {
    this.isDropDownOpen = false;
  }

  openProfile() {
    this.closeDropDown();
    if (this.userService.isAccountActive()) {
      this.router.navigateByUrl('profile');
    } else {
      this.toastrService.warning('Please verify your email to access The Colosseum!');
    }
  }

  buyGMRX(currency: EXCHANGE_CURRENCY | null = null) {
    this.closeDropDown();
    if (this.userService.isAccountActive()) {
      this.dialogService.open(BuyGmrxModalComponent, {
        panelClass: ['dialog-overlay-pane'],
        data: currency
      });
    } else {
      this.toastrService.warning('Please verify your email to access The Colosseum!');
    }
  }

  connectWallet(isOpenBuyGMRXModal = false) {
    if (this.userService.isAccountActive()) {
      this.isOpenBuyGMRXModal = isOpenBuyGMRXModal;
      this.walletConnectService.openModal();
    } else {
      this.toastrService.warning('Please verify your email to access The Colosseum!');
    }
  }

  becomeMember() {
    if (this.userService.isAccountActive()) {
      this.authService.acquireAccount(true, COLOSSEUM_TIERS.SILVER);
    } else {
      this.toastrService.warning('Please verify your email to access The Colosseum!');
    }
  }

  logout() {
    this.closeDropDown();
    this.walletConnectService.disconnectFromWallet();
    this.authService.logout();
  }

  goToColosseum() {
    this.router.navigateByUrl('content');
  }

  openRoot() {
    this.router.navigateByUrl('/');
  }

  signIn() {
    this.authService.openLoginPopup();
  }
}
