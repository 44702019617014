<div class="confirm">
  <div class="header">
    <h2>Email confirmation</h2>
    <button class="icon-Close close-icon" title="Close" (click)="close()"></button>
  </div>
  <div class="modal-content">
    <p>Please verify your email to access The Colosseum!</p>
    <span (click)="resendEmailVerification()">Re-send email</span>
  </div>
</div>
