import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilKeyChanged,
  EMPTY,
  expand,
  filter,
  from,
  map,
  Observable,
  switchMap,
  take
} from 'rxjs';
import Openfort, { EmbeddedState, ThirdPartyOAuthProvider, TokenType } from '@openfort/openfort-js';
import { environment } from '../../../environments/environment';
import {
  Estimate,
  ExchangeRate,
  ExchangeRateToken,
  MoralisNFTResponse,
  MoralisResponse,
  MoralisTokenResponse,
  MoralisTransactionResponse,
  NFT,
  SmartWalletSettings,
  Token,
  TokenWithPrice,
  Transaction,
  TRANSACTION_TYPE,
  TransactionData,
  TransactionResponse
} from './smart-wallet.model';
import { Response } from 'src/app/shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { SmartWalletWelcomeDialogComponent } from './smart-wallet-welcome-dialog/smart-wallet-welcome-dialog.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';
import {
  formatDate,
  getTransactionAddress,
  getTransactionAsset,
  getTransactionDirection,
  transformTransactionType
} from './smart-wallet.helpers';

const gmrxDecimals = 18;

@Injectable({ providedIn: 'root' })
export class SmartWalletService {
  private readonly chain = 'BNB_CHAIN';
  private readonly settingsKey = 'smartWalletSettings';

  public readonly convertTokens: ExchangeRateToken[] = [
    { currencyCode: 'GMRX', tokenAddress: '0x998305efDC264b9674178899FFfBb44a47134a76' },
    { currencyCode: 'USDT', tokenAddress: '0x55d398326f99059ff775485246999027b3197955' },
    { currencyCode: 'USDC', tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d' },
    { currencyCode: 'ETH', tokenAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7' },
    { currencyCode: 'BTC', tokenAddress: '0x7130d2a12b9bcfaae4f2634d864a1ee1ce3ead9c' }
  ];

  private openfort!: Openfort;

  public sendDisabled$ = new BehaviorSubject<boolean>(false);

  private addressSrc = new BehaviorSubject<string>('');
  public address$ = this.addressSrc.pipe(filter((a: string) => !!a));

  get address() {
    return this.addressSrc.value;
  }

  private userSrc = new BehaviorSubject<any>(null);
  public user$ = this.userSrc.asObservable();

  private settingsSrc = new BehaviorSubject<SmartWalletSettings>({
    isPrivateMode: false,
    wasSigned: false,
    agreedToSendTerms: false,
    selectedCurrency: this.convertTokens[0].currencyCode
  });

  get settings() {
    return this.settingsSrc.value;
  }

  public isPrivateMode$ = this.settingsSrc.pipe(map((settings) => !!settings.isPrivateMode));

  private isLoadingSrc = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSrc.asObservable();

  private tokensWithoutPricesSrc = new BehaviorSubject<Token[] | null>(null);
  public tokensWithoutPrices$ = this.tokensWithoutPricesSrc.asObservable();

  private GMRXBalanceSrc = new BehaviorSubject<number | null>(null);
  public GMRXBalance$ = this.GMRXBalanceSrc.asObservable();

  private isLoadingTokensSrc = new BehaviorSubject<boolean>(false);
  public isLoadingTokens$ = this.isLoadingTokensSrc.asObservable();

  private exchangeRatesSrc = new BehaviorSubject<ExchangeRate>({});
  public exchangeRates$ = this.exchangeRatesSrc.asObservable();

  private isLoadingExchangeRatesSrc = new BehaviorSubject<boolean>(false);
  public isLoadingExchangeRates$ = this.isLoadingExchangeRatesSrc.asObservable();

  public tokens$: Observable<TokenWithPrice[] | null> = combineLatest([
    this.tokensWithoutPrices$,
    this.exchangeRates$
  ]).pipe(
    map(([tokensWithoutPrice, exchangeRates]) => {
      if (!tokensWithoutPrice) {
        return null;
      }

      return tokensWithoutPrice.map((tokenWithoutPrice) => {
        const rate = exchangeRates[tokenWithoutPrice.symbol];

        if (!rate) {
          return {
            ...tokenWithoutPrice,
            usd: {
              usdPrice: 0,
              usdPriceDisplay: '$N/A',
              usdTotal: 0,
              usdTotalDisplay: '≈ $N/A'
            }
          };
        }

        const usdTotal = rate * tokenWithoutPrice.balance;

        return {
          ...tokenWithoutPrice,
          usd: {
            usdPrice: rate,
            usdPriceDisplay: `$${rate.toFixed(rate < 0.001 ? 6 : 2)}`,
            usdTotal,
            usdTotalDisplay: `≈ $${usdTotal.toFixed(3)}`
          }
        };
      });
    })
  );

  public totalUSD$ = this.tokens$.pipe(map((tokens) => tokens?.reduce((acc, curr) => acc + curr.usd.usdTotal, 0) || 0));

  public nftsSrc = new BehaviorSubject<NFT[]>([]);
  public nfts$ = this.nftsSrc.asObservable();

  private nftsPaginationKeySrc = new BehaviorSubject<string>('');
  public nftsPaginationKey$ = this.nftsPaginationKeySrc.asObservable();

  private isLoadingNftsSrc = new BehaviorSubject<boolean>(false);
  public isLoadingNfts$ = this.isLoadingNftsSrc.asObservable();

  public transactionsSrc = new BehaviorSubject<Transaction[]>([]);
  public transactions$ = this.transactionsSrc.asObservable();

  private isLoadingTransactionsSrc = new BehaviorSubject<boolean>(false);
  public isLoadingTransactions$ = this.isLoadingTransactionsSrc.asObservable();

  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService,
    private dialogService: MatDialog,
    private router: Router,
    private userService: UserService,
    private toastrService: ToastrService
  ) {
    this.initOpenfort();
  }

  public init(): void {
    this.signIn();
    this.subscribeOnProfile();
  }

  public loadTokens(): void {
    if (this.isLoadingTokensSrc.value) {
      return;
    }

    this.isLoadingTokensSrc.next(true);

    this.address$
      .pipe(
        filter((address) => !!address),
        take(1),
        switchMap(() => combineLatest([this.loadTokensRequest(), this.loadNativeTokenRequest()]))
      )

      .subscribe({
        next: ([tokensResponse, nativeTokenResponse]) => {
          let tokens: Token[] = [];

          if (tokensResponse.success && tokensResponse.data?.tokens) {
            tokens = tokensResponse.data.tokens.map((token: MoralisTokenResponse) => ({
              name: token.name,
              symbol: token.symbol,
              balance: Number((parseInt(token.balance) / 10 ** token.decimals).toFixed(4)),
              decimals: token.decimals,
              contractAddress: token.tokenAddress,
              logo: token.logo
            }));
          }

          if (nativeTokenResponse.success && nativeTokenResponse.data) {
            const bnbDecimals = 18;

            const balanceInBNB = parseFloat(nativeTokenResponse.data.balance) / Math.pow(10, bnbDecimals);

            if (balanceInBNB > 0) {
              tokens.unshift({
                name: 'BNB',
                symbol: 'BNB',
                balance: balanceInBNB,
                decimals: bnbDecimals,
                contractAddress: '0x0000000000000000000000000000000000000000',
                logo: './assets/icons/smart-wallet/bnb.png',
                native: true
              });
            }
          }

          this.GMRXBalanceSrc.next(tokens.find((token) => token.symbol === 'GMRX')?.balance || 0);

          this.tokensWithoutPricesSrc.next(tokens);
          this.isLoadingTokensSrc.next(false);

          this.loadExchangeRates();
        },
        error: () => {
          this.isLoadingTokensSrc.next(false);
        }
      });
  }

  public loadExchangeRates(): void {
    this.isLoadingExchangeRatesSrc.next(true);

    const tokens = this.tokensWithoutPricesSrc.value;

    const exchangeRateTokens: ExchangeRateToken[] = tokens
      ? tokens
          .filter(({ symbol }) => !this.convertTokens.some((token) => token.currencyCode === symbol))
          .map(
            (token) =>
              ({
                currencyCode: token.symbol,
                tokenAddress: token.contractAddress
              }) as ExchangeRateToken
          )
          .concat(this.convertTokens)
      : this.convertTokens;

    this.loadExchangeRatesRequest(exchangeRateTokens).subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.exchangeRatesSrc.next(response.data);
        }

        this.isLoadingExchangeRatesSrc.next(false);
      },
      error: () => {
        this.toastrService.error('Error loading exchange rates.');
        this.isLoadingExchangeRatesSrc.next(false);
      }
    });
  }

  public loadNfts(): void {
    if (this.isLoadingNftsSrc.value) {
      return;
    }

    this.isLoadingNftsSrc.next(true);

    this.address$
      .pipe(
        filter((address) => !!address),
        take(1),
        switchMap(() => this.loadNFTsRequest())
      )
      .subscribe({
        next: (response) => {
          if (response.success && response.data) {
            console.log('nfts response', response);

            const nfts: NFT[] = response.data.result?.map((nft) => {
              const metadata = nft.metadata ? JSON.parse(nft.metadata) : {};
              const imageUrl = metadata ? metadata.image || metadata.imageUrl : null;
              return {
                amount: nft.amount || '1',
                tokenId: nft.tokenId,
                name: nft.name,
                metadata,
                imageUrl,
                tokenAddress: nft.tokenAddress,
                symbol: nft.symbol,
                contractType: nft.contractType,
                contractAddress: nft.tokenAddress,
                tokenHash: nft.tokenHash
              };
            });

            this.nftsSrc.next([...this.nftsSrc.value, ...nfts]);
          }

          if (response.data?.cursor) {
            this.nftsPaginationKeySrc.next(response.data.cursor);
          }

          this.isLoadingNftsSrc.next(false);
        },
        error: () => {
          this.isLoadingNftsSrc.next(false);
        }
      });
  }

  public getNftByTokenHash(tokenHash: string): NFT | null {
    const nfts = this.nftsSrc.value;

    if (!nfts.length || !nfts.find((nft) => nft.tokenHash === tokenHash)) {
      return null;
    }

    return nfts.find((nft) => nft.tokenHash === tokenHash)!;
  }

  public invalidateNfts(): void {
    this.nftsSrc.next([]);
    this.nftsPaginationKeySrc.next('');
  }

  public loadTransactions(): void {
    if (this.isLoadingTransactionsSrc.value) {
      return;
    }

    this.isLoadingTransactionsSrc.next(true);

    let transactions: Transaction[] = [];

    this.address$
      .pipe(
        filter((address) => !!address),
        take(1),
        switchMap(() => this.loadTransactionsRequest())
      )
      .subscribe({
        next: (response) => {
          if (response.success) {
            console.log('transactions response', response);

            const formattedTransactions: Transaction[] =
              response.data?.result?.map((transaction) => {
                const transactionCategory =
                  transaction.category === TRANSACTION_TYPE.CONTRACT_INTERACTION &&
                  transaction.nftTransfers[0]?.direction === 'send'
                    ? TRANSACTION_TYPE.NFT_SEND
                    : transaction.category;

                return {
                  asset: getTransactionAsset(transaction, transactionCategory),
                  direction: getTransactionDirection(transactionCategory),
                  category: transactionCategory,
                  type: transformTransactionType(transactionCategory),
                  blockStamp: formatDate(transaction.blockTimestamp),
                  usd: 0,
                  network: 'BSC',
                  fee: Number(transaction.transactionFee),
                  address: getTransactionAddress(transaction, transactionCategory),
                  txid: transaction.hash,
                  success: Number(transaction.receiptStatus) === 1
                };
              }) || [];

            const prevTransactions = transactions || [];
            transactions = [...prevTransactions, ...formattedTransactions];

            this.transactionsSrc.next(transactions);

            if (!response.data?.cursor) {
              this.isLoadingTransactionsSrc.next(false);
            }
          } else {
            this.isLoadingTransactionsSrc.next(false);
          }
        },
        error: () => {
          this.isLoadingTransactionsSrc.next(false);
        }
      });
  }

  public getTransactionByTxID(txid: string): Transaction | null {
    const transactions = this.transactionsSrc.value;

    if (!transactions.length || !transactions.find((transaction) => transaction.txid === txid)) {
      return null;
    }

    return transactions.find((transaction) => transaction.txid === txid)!;
  }

  public configureEmbeddedSigner(recoveryPassword?: string): Observable<void> {
    return from(this.openfort.configureEmbeddedSigner(environment.openfort.chainId, null, recoveryPassword));
  }

  public logout(): void {
    if (this.openfort) {
      this.openfort.logout();
    }

    this.userSrc.next(null);
    this.addressSrc.next('');
    this.tokensWithoutPricesSrc.next(null);
    this.exchangeRatesSrc.next({});
    this.nftsSrc.next([]);
    this.nftsPaginationKeySrc.next('');
    this.transactionsSrc.next([]);
  }

  private initOpenfort(): void {
    this.openfort = new Openfort({
      baseConfiguration: {
        publishableKey: environment.openfort.publicKey
      },
      shieldConfiguration: {
        shieldPublishableKey: environment.openfort.shieldApiKey
      }
    });
  }

  private async signIn() {
    this.isLoadingSrc.next(true);

    await this.authenticateWithThirdPartyProvider();
    const state = this.openfort.getEmbeddedState();

    if (state === EmbeddedState.READY) {
      this.onAuthenticateSuccess();
    } else {
      const welcomeDialogRef = this.dialogService.open(SmartWalletWelcomeDialogComponent);
      welcomeDialogRef.afterClosed().subscribe(async (enteredPassword: boolean) => {
        if (!enteredPassword) {
          this.router.navigateByUrl(`/content`);
        } else {
          this.onAuthenticateSuccess();
        }
      });
    }
  }

  private async onAuthenticateSuccess() {
    this.isLoadingSrc.next(true);
    const response = await this.openfort.getAccount();
    const address = response.address;
    const user = await this.openfort.getUser();

    this.userSrc.next(user);
    this.addressSrc.next(address);
    this.isLoadingSrc.next(false);
  }

  private async authenticateWithThirdPartyProvider() {
    const accessToken = this.authStateService.accessTokenSnapshot;
    await this.openfort.authenticateWithThirdPartyProvider({
      provider: ThirdPartyOAuthProvider.CUSTOM,
      token: accessToken,
      tokenType: TokenType.CUSTOM_TOKEN
    });
  }

  private subscribeOnProfile(): void {
    this.userService.userInfo$.pipe(distinctUntilKeyChanged('id')).subscribe(({ id }) => {
      if (!id) {
        return;
      }

      const savedSettings = localStorage.getItem(this.settingsKey);
      const profileSettings: SmartWalletSettings = JSON.parse(savedSettings || '{}')[id];

      if (profileSettings) {
        this.settingsSrc.next(profileSettings);
      } else {
        this.updateSettings({});
      }
    });
  }

  public updateSettings(settings: Partial<SmartWalletSettings>) {
    const id = this.userService.userInfo$.value.id;
    const savedSettings = localStorage.getItem(this.settingsKey);

    this.settingsSrc.next({ ...this.settingsSrc.value, ...settings });
    localStorage.setItem(
      this.settingsKey,
      JSON.stringify({ ...JSON.parse(savedSettings || '{}'), [id]: this.settingsSrc.value })
    );
  }

  private loadNativeTokenRequest(): Observable<Response<{ balance: string }>> {
    return this.http.get<Response<MoralisTokenResponse>>(
      `${environment.gaiminApi}/moralis/native?address=${this.addressSrc.value}&chain=${this.chain}`
    );
  }

  private loadTokensRequest(): Observable<Response<{ tokens: MoralisTokenResponse[] }>> {
    return this.http.get<Response<{ tokens: MoralisTokenResponse[] }>>(
      `${environment.gaiminApi}/moralis/token?address=${this.addressSrc.value}&chain=${this.chain}`
    );
  }

  private loadExchangeRatesRequest(tokens: ExchangeRateToken[]): Observable<Response<ExchangeRate>> {
    return this.http.post<Response<ExchangeRate>>(`${environment.gaiminApi}/sw/exchange-rate`, {
      currencies: tokens
    });
  }

  private loadNFTsRequest(): Observable<Response<MoralisResponse<MoralisNFTResponse[]>>> {
    return this.http.get<Response<MoralisResponse<MoralisNFTResponse[]>>>(
      `${environment.gaiminApi}/moralis/nft?address=${this.addressSrc.value}&chain=${this.chain}&cursor=${this.nftsPaginationKeySrc.value}`
    );
  }

  private loadTransactionsRequest(): Observable<Response<MoralisResponse<MoralisTransactionResponse[]>>> {
    const apiUrl = `${environment.gaiminApi}/moralis/history?address=${this.addressSrc.value}&chain=${this.chain}`;

    const initialRequest = this.http.get<Response<MoralisResponse<MoralisTransactionResponse[]>>>(apiUrl);

    return initialRequest.pipe(
      expand((response) => {
        if (response.data?.cursor) {
          return this.http.get<Response<MoralisResponse<MoralisTransactionResponse[]>>>(
            apiUrl + `&cursor=${response.data.cursor}`
          );
        }

        return EMPTY;
      })
    );
  }

  public getFee(data: TransactionData): Observable<number> {
    return this.http
      .post<Response<Estimate>>(`${environment.gaiminApi}/sw/estimate`, {
        playerId: this.userSrc.value.id,
        fromAddress: this.addressSrc.value,
        ...data
      })
      .pipe(
        map((response) => Number(response.data?.estimatedTXGasFeeToken) / 10 ** gmrxDecimals),
        catchError((_, caught) => {
          this.toastrService.error('Something went wrong, please try again later');
          return caught;
        })
      );
  }

  public sendTransactionRequest(data: TransactionData): Observable<Response<TransactionResponse>> {
    return this.http.post<Response<TransactionResponse>>(`${environment.gaiminApi}/sw/intent`, {
      playerId: this.userSrc.value.id,
      fromAddress: this.addressSrc.value,
      ...data
    });
  }

  public async sendSignatureTransactionIntentRequest(transactionId: string, signature: string) {
    return this.openfort.sendSignatureTransactionIntentRequest(transactionId, signature);
  }
}
