<div class="main-sidebar__wrapper" [ngClass]="{ active: isSideBarOpen$ | async }" (click)="clickOutside($event)">
  <div class="main-sidebar" [ngClass]="{ active: isSideBarOpen$ | async }">
    <span class="close-btn icon-Close-Bold" (click)="closeSidebar()"></span>
    <div class="main-sidebar__header">
      <img src="../../../../assets/gladiators-logo.svg" alt="Gaimin Gladiators" (click)="openRoot()" lazyLoad />
      <div class="main-sidebar__header-user" (click)="openProfile()">
        <h3>{{ (userService.userInfo$ | async)?.firstName }} {{ (userService.userInfo$ | async)?.lastName }}</h3>
        <h4>{{ (userService.userInfo$ | async)?.email }}</h4>
        <span class="icon-Arrow-Right"></span>
      </div>
    </div>
    <ul class="main-sidebar__list">
      <ng-container *ngFor="let item of sideMenu$ | async">
        <li
          *ngIf="item.dropdown"
          class="main-sidebar__item"
          [ngClass]="{ disabled: item.disabled, hideMobile: item.bottomMenu }"
          [tooltip]="item.disabled ? 'Coming soon' : null"
          [tooltipPosition]="'right'"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          (click)="openDropdown(item)">
          <span [ngClass]="item.icon" class="icon"></span>
          {{ item.title }}
          <span class="icon-Arrow-Right arrow"></span>
        </li>

        <li
          *ngIf="!item.dropdown"
          class="main-sidebar__item"
          [ngClass]="{ disabled: item.disabled, hideMobile: item.bottomMenu }"
          routerLinkActive="main-sidebar__item-active"
          [routerLink]="item.disabled ? null : item.redirectLink"
          [tooltip]="item.disabled ? 'Coming soon' : null"
          [tooltipPosition]="'right'"
          (click)="!item.disabled ? closeSidebar() : undefined"
          >
          <span [ngClass]="item.icon" class="icon"></span>
          {{ item.title }}
          <span class="icon-Arrow-Right arrow"></span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<div class="mobile-menu">
  <ul>
    <ng-container *ngFor="let item of mobileMenu$ | async">
      <li *ngIf="item.dropdown" [ngClass]="{ disabled: item.disabled }" (click)="toggleBottomSheet(item)">
        <span [ngClass]="item.icon" class="icon"></span>
        {{ item.title }}
      </li>

      <li
        *ngIf="!item.dropdown"
        [ngClass]="{ disabled: item.disabled }"
        routerLinkActive="active"
        [routerLink]="item.disabled ? null : item.redirectLink">
        <span [ngClass]="item.icon" class="icon"></span>
        {{ item.title }}
      </li>
    </ng-container>
    <li (click)="openSidebar()">
      <span class="icon icon-Menu"></span>
      Menu
    </li>
  </ul>
</div>
