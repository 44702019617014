import { Injectable } from '@angular/core';
import { Address, readContract, writeContract, WriteContractResult } from '@wagmi/core';
import { getContractABI_Subscription, getGmrxContractABI } from '../../../tools/contractAbi';
import { environment } from '../../../environments/environment';
import { COLOSSEUM_TIERS, CONTRACT_EVENT_NAMES, PAYMENT_STEPS } from '../enums';
import { AuthService } from './auth.service';
import { createPublicClient, http, Log } from 'viem';
import { bsc, bscTestnet } from 'viem/chains';
import { BehaviorSubject, from, Observable, Subscriber, Subscription, take, timer } from 'rxjs';
import { EventResponse } from '../interfaces';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Router } from '@angular/router';
import { SubscriptionConfirmationModalComponent } from '../components/modals/subscription-confirmation-modal/subscription-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { catchError, filter } from 'rxjs/operators';
import { SubscriptionPaymentModalComponent } from '../components/modals/subscription-payment-modal/subscription-payment-modal.component';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  unlockedDays: unknown = 0;
  userCurrentTier: unknown;

  @AutoUnsubscribe()
  unsubscribeSub: Subscription | undefined;

  approvalSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  swapTokenHash$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  approvalHash$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentPaymentStep$: BehaviorSubject<PAYMENT_STEPS> = new BehaviorSubject<PAYMENT_STEPS>(
    PAYMENT_STEPS.ALLOW_GMRX_SPEND
  );
  errorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isTransactionPending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  publicClient = createPublicClient({
    chain: environment.production ? bsc : bscTestnet,
    transport: http()
  });

  get colosseumSubscriptionContract() {
    return environment.colosseumSubscriptionContract as `0x${string}`;
  }

  get gmrxTokenContractAddress() {
    return environment.gmrxTokenContractAddress as `0x${string}`;
  }

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthService,
    private dialogService: MatDialog
  ) {}

  /** Read methods */

  /**
   * This method retrieves the remaining lock days from the subscription contract.
   *
   * @method getRemainingLockDays
   * @param {Address} walletAddress - The connected wallet address.
   */
  async getRemainingLockDays(walletAddress: Address): Promise<void> {
    try {
      this.unlockedDays = await readContract({
        address: this.colosseumSubscriptionContract,
        abi: getContractABI_Subscription(),
        functionName: 'getRemainingLockDays',
        args: [walletAddress]
      });
    } catch (error) {
      this.unlockedDays = 0n;
    }
  }

  /**
   * This method retrieves the subscription price from the subscription contract.
   *
   * @method getSubscriptionPrice
   * @param {string} functionName - The name of the function to call on the contract.
   */
  async getSubscriptionPrice(functionName: string): Promise<number | null> {
    try {
      const result = await readContract({
        address: this.colosseumSubscriptionContract,
        abi: getContractABI_Subscription(),
        functionName: `get${functionName}SubscriptionPrice`
      });
      return result as number;
    } catch (error) {
      console.error('Read contract error:', error);
      return null;
    }
  }

  /**
   * This method retrieves the stake balance from the subscription contract.
   *
   * @method getStakeBalance
   * @param {string} walletAddress - The address of the wallet for which to retrieve the stake balance.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  async getStakeBalance(walletAddress: string): Promise<number> {
    try {
      const result = await readContract({
        address: this.colosseumSubscriptionContract,
        abi: getContractABI_Subscription(),
        functionName: `getStakeBalance`,
        args: [walletAddress]
      });
      return result as number;
    } catch (error) {
      return 0;
    }
  }

  /**
   * This method checks the allowance of the GMRX token for a specific wallet address.
   *
   * @method checkAllowanceGMRX
   * @param {Address} walletAddress - The address of the wallet for which to check the allowance.
   * @returns {Promise<number>} A promise that resolves with the allowance of the GMRX token.
   */
  async checkAllowanceGMRX(walletAddress: Address): Promise<number> {
    try {
      const result = await readContract({
        address: this.gmrxTokenContractAddress,
        abi: getGmrxContractABI(),
        functionName: 'allowance',
        args: [walletAddress, this.colosseumSubscriptionContract]
      });
      return result as number;
    } catch (error) {
      return 0;
    }
  }

  /**
   * This method retrieves the staked tier from the subscription contract.
   *
   * @method getStakeTier
   * @param walletAddress
   */
  async getStakeTier(walletAddress: Address): Promise<void> {
    try {
      this.userCurrentTier = await readContract({
        address: this.colosseumSubscriptionContract,
        abi: getContractABI_Subscription(),
        functionName: 'getStakeTier',
        args: [walletAddress]
      });
    } catch (error) {
      console.error('Read contract error:', error);
    }
  }

  /** Write methods */

  /**
   * This method handles the process of subscribing a wallet to a specific tier.
   * If the required GMRX allowance is greater than zero, it retrieves the allowance,
   * waits for approval, and then subscribes the wallet to the specified tier.
   * If the required GMRX allowance is not greater than zero, it directly subscribes the wallet to the specified tier.
   *
   * @method subscribeProcess
   * @param {COLOSSEUM_TIERS} tier - The tier to which the wallet should be subscribed.
   * @param {Address} walletAddress - The address of the wallet that should be subscribed.
   * @returns {Promise<void>} A promise that resolves when the subscription operation is complete.
   */
  async subscribeProcess(tier: COLOSSEUM_TIERS, walletAddress: Address): Promise<void> {
    try {
      this.openSubscriptionPaymentPopup();

      const tierType = tier.charAt(0) + tier.slice(1).toLowerCase();
      const subscriptionPrice: number | null = await this.getSubscriptionPrice(tierType);
      const allowanceBalance: number = await this.checkAllowanceGMRX(walletAddress);
      const stakedBalance: number = await this.getStakeBalance(walletAddress);
      const allowReqSum = (subscriptionPrice as number) - stakedBalance - allowanceBalance;

      const subscribeAndNotify = async () => {
        await this.subscribeTo(tierType);
        this.getEventSubscriptionChanged();
      };

      if (allowReqSum > 0) {
        await this.retrieveAllowanceGMRX(allowReqSum);
        this.getEventGmrxApprove();
        this.approvalSuccess$
          .pipe(
            filter((isApprove) => isApprove),
            take(1)
          )
          .subscribe(async () => {
            this.currentPaymentStep$.next(PAYMENT_STEPS.SWAP_TOKEN);
            await subscribeAndNotify();
            this.approvalSuccess$.complete();
          });
      } else {
        this.currentPaymentStep$.next(PAYMENT_STEPS.SWAP_TOKEN);
        await subscribeAndNotify();
      }
    } catch (error) {
      await this.handleError(error as Error, 'subscribeProcess');
    }
  }

  /**
   * This method retrieves the subscription .
   *
   * @method subscribeTo
   * @param {string} tier - tier for subscribe.
   * @return {Promise<void>} - A promise that resolves with the response of the contract call.
   */
  async subscribeTo(tier: string): Promise<void> {
    from(
      writeContract({
        address: this.colosseumSubscriptionContract,
        abi: getContractABI_Subscription(),
        functionName: `subscribe${tier}`
      })
    )
      .pipe(
        catchError(async (error) => {
          await this.handleError(error as Error, 'subscribeTo');
        }),
        take(1)
      )
      .subscribe((data) => {
        if ((data as WriteContractResult).hash) {
          this.currentPaymentStep$.next(PAYMENT_STEPS.CONFIRMAITON_SWAP);
          this.swapTokenHash$.next((data as WriteContractResult).hash);
        }
      });
  }

  /**
   * This method handles errors.
   *
   * @method handleError
   * @param {Error} error - The error to handle.
   * @param {string} methodName - The method name where is handle.
   */
  async handleError(error: Error, methodName: string): Promise<void> {
    console.error(`${methodName} error:`, error);

    this.errorMessage$.next('Something went wrong, please try again later');

    switch (methodName) {
      case 'subscribeTo':
        this.currentPaymentStep$.next(PAYMENT_STEPS.SWAP_TOKEN);
        break;
      case 'retrieveAllowanceGMRX':
        this.currentPaymentStep$.next(PAYMENT_STEPS.ALLOW_GMRX_SPEND);
        break;
    }

    switch (true) {
      case error.message.includes('Connector'):
        this.errorMessage$.next('Please connect your wallet');
        break;
      case error.message.includes('Stake is locked'):
        this.errorMessage$.next(
          'This unsubscribe  is temporarily unavailable. You can check the unlock time in your profile details.'
        );
        break;
      case error.message.includes('rejected'):
        this.errorMessage$.next('Transaction was denied by the user');
        break;
      case error.message.includes('Insufficient balance'):
        this.errorMessage$.next(
          'Your transaction cannot be completed due to insufficient balance. Please check your balance and try again.'
        );
        break;
      case error.message.includes('Insufficient allowance'):
        this.errorMessage$.next(
          'Your transaction cannot be completed due to insufficient allowance. Please check your balance and try again.'
        );
        break;
      case error.message.includes('No staked balance found'):
        if (methodName === 'Unsubscribe') {
          await this.unsubscribeRequest();
        }
        return;
    }

    this.toastrService.error(this.errorMessage$.value);
  }

  /**
   * This method retrieves the allowance of the GMRX token.
   *
   * @method retrieveAllowanceGMRX
   * @return {Promise<WriteContractResult>} - A promise that resolves with the response of the contract call.
   * @param allowance
   */
  async retrieveAllowanceGMRX(allowance: number): Promise<void> {
    from(
      writeContract({
        address: this.gmrxTokenContractAddress,
        abi: getGmrxContractABI(),
        functionName: 'approve',
        args: [this.colosseumSubscriptionContract, allowance]
      })
    )
      .pipe(
        catchError(async (error) => {
          await this.handleError(error as Error, 'retrieveAllowanceGMRX');
        }),
        take(1)
      )
      .subscribe((data) => {
        if ((data as WriteContractResult).hash) {
          this.currentPaymentStep$.next(PAYMENT_STEPS.CONFIRMAITON_SPEND);
          this.approvalHash$.next((data as WriteContractResult).hash);
        }
      });
  }

  /**
   * This method unsubscribes the user from their current subscription.
   *
   * @method unsubscribeFrom
   */
  async unsubscribeFrom(): Promise<void> {
    try {
      const response: WriteContractResult = await writeContract({
        address: this.colosseumSubscriptionContract,
        abi: getContractABI_Subscription(),
        functionName: 'withdrawStake'
      });
      console.log('Unsubscribe HASH:', response);
      this.getEventSubscriptionCancelled();
    } catch (error) {
      await this.handleError(error as Error, 'Unsubscribe');
    }
  }

  /** Events methods */

  /**
   * This method watches for specific events on the subscription contract.
   *
   * @method watchContractEvent
   * @param {CONTRACT_EVENT_NAMES} eventName - The name of the event to watch.
   * @param address
   * @param abi
   * @return {Observable<Log[]>} - An observable that emits the logs of the watched event.
   */
  watchContractEvent(eventName: CONTRACT_EVENT_NAMES, address: any, abi: any): Observable<Log[]> {
    return new Observable((subscriber: Subscriber<Log[]>) => {
      const event = this.publicClient.watchContractEvent({
        address,
        abi,
        eventName,
        onLogs: async (logs: Log[]) => {
          await this.handleEventType(logs, eventName);
          subscriber.next(logs);
          subscriber.complete();
        },
        onError: async (error) => {
          subscriber.complete();
          console.log(`Event ${eventName} error:`, error);
        }
      });

      return () => {
        event();
      };
    });
  }

  /**
   * This method watches for the 'Changed' event on the Colosseum subscription contract.
   * It subscribes to the event and does not return anything.
   *
   * @method getEventSubscriptionChanged
   * @private
   * @returns {void}
   */
  private getEventSubscriptionChanged(): void {
    this.watchContractEvent(
      CONTRACT_EVENT_NAMES.CHANGED,
      this.colosseumSubscriptionContract,
      getContractABI_Subscription()
    ).subscribe();
  }

  /**
   * This method watches for the 'Cancelled' event on the Colosseum subscription contract.
   * It subscribes to the event and does not return anything.
   *
   * @method getEventSubscriptionCancelled
   * @private
   * @returns {void}
   */
  private getEventSubscriptionCancelled(): void {
    this.watchContractEvent(
      CONTRACT_EVENT_NAMES.CANCELLED,
      this.colosseumSubscriptionContract,
      getContractABI_Subscription()
    ).subscribe();
  }

  /**
   * This method watches for the 'Approval' event on the GMRX token contract.
   * It subscribes to the event and does not return anything.
   *
   * @method getEventGmrxApprove
   * @private
   * @returns {void}
   */
  private getEventGmrxApprove(): void {
    this.watchContractEvent(
      CONTRACT_EVENT_NAMES.APPROVAL,
      this.gmrxTokenContractAddress,
      getGmrxContractABI()
    ).subscribe();
  }

  /**
   * Handles the subscription based on the event name.
   *
   * @param {CONTRACT_EVENT_NAMES} eventName - The name of the event.
   * @param {string} subscriptionName - The name of the subscription.
   */
  private async eventHandler(eventName: CONTRACT_EVENT_NAMES, subscriptionName: string): Promise<void> {
    switch (eventName) {
      case CONTRACT_EVENT_NAMES.CHANGED:
        this.currentPaymentStep$.next(PAYMENT_STEPS.SWAP_TOKEN_CONFIRMED);
        timer(2000).subscribe(() => {
          this.authService.acquireAccount(true, subscriptionName as COLOSSEUM_TIERS);
          this.router.navigateByUrl('content');
          this.subscriptionPaymentCleanUp();
        });
        break;
      case CONTRACT_EVENT_NAMES.CANCELLED:
        await this.unsubscribeRequest();
        this.currentPaymentStep$.next(PAYMENT_STEPS.ALLOW_GMRX_SPEND);
        break;
      case CONTRACT_EVENT_NAMES.APPROVAL:
        this.currentPaymentStep$.next(PAYMENT_STEPS.SPEND_CONFIRMED);
        this.approvalSuccess$.next(true);
        break;
      default:
        console.log(`Unhandled event: ${eventName}`);
    }
  }

  /**
   * Unsubscribes from the current subscription.
   */
  async unsubscribeRequest(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.unsubscribeSub = this.authService.unsubscribeSubscription().subscribe({
        next: (unsubscribeResponse) => {
          if (unsubscribeResponse.success) {
            console.log('Successfully unsubscribed');
            this.toastrService.success('Successfully unsubscribed');
          }
        },
        error: (error) => {
          console.error(error);
          reject(error);
        },
        complete: () => {
          console.log('Unsubscription process completed.');
          this.authService.updateSubscriptionInfo();
          resolve();
        }
      });
    });
  }

  /**
   * Updates the subscription type based on the logs and event name.
   *
   * @method handleEventType
   * @param {EventResponse|Log[]} logs - The logs from the contract event.
   * @param {CONTRACT_EVENT_NAMES} eventName - The name of the event.
   */
  async handleEventType(logs: Log[] | EventResponse, eventName: CONTRACT_EVENT_NAMES): Promise<void> {
    if (Array.isArray(logs)) {
      const eventResponse = logs[0] as unknown as EventResponse;
      const { subscriptionName } = eventResponse.args;
      await this.eventHandler(eventName, subscriptionName as COLOSSEUM_TIERS);
    } else {
      const { subscriptionName } = logs.args;
      await this.eventHandler(eventName, subscriptionName as COLOSSEUM_TIERS);
    }
  }

  /**
   * Opens a subscription confirmation modal and subscribes to the selected tier type.
   *
   * @method openUpgradePopup
   * @param {COLOSSEUM_TIERS} tierType - The type of the tier to subscribe to.
   * @param {Address} walletAddress - The wallet address of the user.
   * @param {string} buttonName - The button type of the user action.
   */
  async openUpgradePopup(tierType: COLOSSEUM_TIERS, walletAddress: Address, buttonName?: string) {
    const tierName = tierType.charAt(0) + tierType.slice(1).toLowerCase();
    const dialogRef = this.dialogService.open(SubscriptionConfirmationModalComponent, {
      panelClass: ['dialog-overlay-pane'],
      data: [tierName, buttonName ?? null]
    });
    dialogRef.componentRef?.instance.confirmAction.subscribe(async () => {
      await this.subscribeProcess(tierType, walletAddress);
    });
  }

  openSubscriptionPaymentPopup() {
    this.subscriptionPaymentCleanUp();
    this.dialogService.open(SubscriptionPaymentModalComponent, {
      panelClass: ['dialog-overlay-pane']
    });
  }

  subscriptionPaymentCleanUp() {
    this.dialogService.closeAll();
    this.currentPaymentStep$.next(PAYMENT_STEPS.ALLOW_GMRX_SPEND);
    this.approvalSuccess$.next(false);
    this.approvalHash$.next('');
    this.swapTokenHash$.next('');
    this.errorMessage$.next('');
  }
}
