<div class="login modal-content">
  <button *ngIf="!data.isFullScreen" class="icon-Close close-icon" title="Close" (click)="closeModal()"></button>
  <div class="login__logo" *ngIf="data.isFullScreen">
    <img src="assets/gladiators-logo.svg" alt="Gaimin Gladiators" lazyLoad />
  </div>
  <div class="login__title">
    <h2>{{ isRegister ? 'Join The Colosseum' : 'Sign in' }}</h2>
    <h3>
      {{ isRegister ? 'Already have an account?' : 'Do not have an account?' }}
      <span (click)="toggleFormType()">{{ isRegister ? 'Sign in' : 'Sign up' }}</span>
    </h3>
  </div>

  <form [formGroup]="isRegister ? registerForm : loginForm" (ngSubmit)="onSubmit()">
    <label class="login__label" for="email">
      Email
      <input formControlName="email" class="login__field" type="text" placeholder="" id="email" />

      <div *ngIf="!isRegister">
        <ng-container *ngIf="loginForm.get('email')!.invalid && loginForm.get('email')!.dirty">
          <span *ngIf="loginForm.get('email')!.hasError('required')" class="login__field__error">
            Email should be present
          </span>
          <span *ngIf="loginForm.get('email')!.hasError('email')" class="login__field__error">
            Valid email address required
          </span>
        </ng-container>
      </div>

      <div *ngIf="isRegister">
        <span
          *ngIf="registerForm.get('email')!.hasError('required') && registerForm.get('email')!.dirty"
          class="login__field__error">
          Email should be present
        </span>
        <ng-container
          *ngIf="
            registerForm.get('email')!.hasError('minlength') || registerForm.get('email')!.hasError('maxlength');
            else email_pattern
          ">
          <span class="login__field__error">
            Email should have
            <mark>{{ emailMinLength }} - {{ emailMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ registerForm.get('email')!.value!.length }}</mark>
          </span>
        </ng-container>

        <ng-template #email_pattern>
          <ng-container *ngIf="registerForm.get('email')!.hasError('pattern')">
            <span class="login__field__error">Valid email address required</span>
          </ng-container>
        </ng-template>
      </div>
    </label>

    <label *ngIf="isRegister" class="login__label" for="firstName">
      First Name
      <input formControlName="firstName" class="login__field" type="text" placeholder="" id="firstName" />
      <ng-container *ngIf="registerForm.get('firstName')!.invalid && registerForm.get('firstName')!.dirty">
        <span *ngIf="registerForm.get('firstName')!.hasError('required')" class="login__field__error">
          First Name should be present
        </span>

        <ng-container
          *ngIf="
            registerForm.get('firstName')!.hasError('minlength') ||
              registerForm.get('firstName')!.hasError('maxlength');
            else firstName_pattern
          ">
          <span class="login__field__error"
            >First Name should have <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark> characters. You currently
            typed <mark>{{ registerForm.get('firstName')!.value!.length }}</mark></span
          >
        </ng-container>

        <ng-template #firstName_pattern>
          <ng-container>
            <span
              *ngIf="registerForm.get('firstName')!.hasError('pattern'); else firstName_complexity"
              class="login__field__error">
              First Name should have only latin characters, hyphen and <mark>1</mark> space
            </span>
          </ng-container>
        </ng-template>
        <ng-template #firstName_complexity>
          <span
            *ngIf="
              !registerForm.get('firstName')?.hasError('pattern') &&
              registerForm.get('firstName')?.hasError('complexity')
            "
            class="login__field__error">
            Only one space can be used
          </span>
        </ng-template>
      </ng-container>
    </label>

    <label *ngIf="isRegister" class="login__label" for="lastName">
      Last Name
      <input formControlName="lastName" class="login__field" type="text" placeholder="" id="lastName" />
      <ng-container *ngIf="registerForm.get('lastName')!.invalid && registerForm.get('lastName')!.dirty">
        <span *ngIf="registerForm.get('lastName')!.hasError('required')" class="login__field__error">
          Last Name should be present
        </span>

        <ng-container
          *ngIf="
            registerForm.get('lastName')!.hasError('minlength') || registerForm.get('lastName')!.hasError('maxlength');
            else lastName_pattern
          ">
          <span class="login__field__error"
            >Last Name should have <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark> characters. You currently
            typed <mark>{{ registerForm.get('lastName')!.value!.length }}</mark></span
          >
        </ng-container>

        <ng-template #lastName_pattern>
          <ng-container
            ><span
              *ngIf="registerForm.get('lastName')!.hasError('pattern'); else lastName_complexity"
              class="login__field__error">
              Last Name should have only latin characters, hyphen and <mark>1</mark> space
            </span></ng-container
          >
        </ng-template>
        <ng-template #lastName_complexity>
          <span
            *ngIf="
              !registerForm.get('lastName')?.hasError('pattern') && registerForm.get('lastName')?.hasError('complexity')
            "
            class="login__field__error">
            Only one space can be used
          </span>
        </ng-template>
      </ng-container>
    </label>

    <label class="login__label" for="password">
      Password

      <password-input formControlName="password" [id]="'password'"></password-input>

      <div *ngIf="!isRegister">
        <span
          *ngIf="loginForm.get('password')!.hasError('required') && loginForm.get('password')!.dirty"
          class="login__field__error">
          Password should be present
        </span>
      </div>

      <div *ngIf="isRegister">
        <ng-container *ngIf="registerForm.get('password')!.invalid && registerForm.get('password')!.dirty">
          <span *ngIf="registerForm.get('password')!.hasError('required')" class="login__field__error">
            Password should be present
          </span>

          <ng-container
            *ngIf="
              registerForm.get('password')!.hasError('minlength') ||
                registerForm.get('password')!.hasError('maxlength');
              else password_complexity
            ">
            <span class="login__field__error">
              Password should have <mark>{{ passwordMinLength }} - {{ passwordMaxLength }}</mark> characters. You
              currently typed
              <mark>{{ registerForm.get('password')!.value!.length }}</mark>
            </span>
          </ng-container>

          <ng-template #password_complexity>
            <ng-container *ngIf="registerForm.get('password')!.hasError('complexity'); else password_pattern">
              <span class="login__field__error"
                >Password should contain at least 1 lowercase character, 1 uppercase character, 1 digit, 1 symbol</span
              >
            </ng-container>
          </ng-template>

          <ng-template #password_pattern>
            <ng-container *ngIf="registerForm.get('password')!.hasError('pattern')">
              <span class="login__field__error">Password can have latin characters, digits and symbols</span>
            </ng-container>
          </ng-template>
        </ng-container>
      </div>
    </label>

    <div class="login__field__checkboxes">
      <div class="login__field__checkbox">
        <mat-checkbox *ngIf="isRegister" [disableRipple]="true" formControlName="tos"
          >* I agree to the <a (click)="closeModal()" routerLink="/terms">Terms & Conditions</a></mat-checkbox
        >
      </div>
      <div class="login__field__checkbox">
        <mat-checkbox *ngIf="isRegister" [disableRipple]="true" formControlName="privacyPolicy"
          >* I agree to the <a (click)="closeModal()" routerLink="/policy">Privacy Policy</a></mat-checkbox
        >
      </div>
      <div class="login__field__checkbox">
        <mat-checkbox *ngIf="isRegister" [disableRipple]="true" formControlName="mailingList"
          >I agree to be included in The Colosseum mailing list</mat-checkbox
        >
      </div>
    </div>

    <app-button
      [attr.disabled]="isRegister ? registerForm.invalid : loginForm.invalid"
      [buttonData]="{ name: isRegister ? 'Sign up' : 'Sign in', size: BUTTON_SIZE.LG }"></app-button>
  </form>

  <div class="login__divider"><span>OR</span></div>

  <app-socials-login [isRegisterForm]="isRegister"></app-socials-login>

  <span *ngIf="!isRegister" class="login__link">
    <a href="https://okta.gaimin.net/signin/forgot-password">Forgot password?</a>
  </span>
  <app-loader></app-loader>
</div>
