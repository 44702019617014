import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return authService.isLoggedIn$.pipe(
    map((loggedIn) => {
      if (loggedIn) {
        return true;
      } else {
        const utmSource = route.queryParams['utmSource'];
        const redirectUrl = utmSource ? `/?utmSource=${utmSource}` : '/';
        router.navigateByUrl(redirectUrl);
        return false;
      }
    }),
    catchError((err) => {
      console.log('AuthGuard err:', err);
      router.navigateByUrl('/');
      return of(false);
    })
  );
};
