import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, filter, map, switchMap, tap } from 'rxjs';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { LeaderboardInfoModalComponent } from '../leaderboard-info-modal/leaderboard-info-modal.component';
import { HowToEarnPointsComponent } from '../how-to-earn-points/how-to-earn-points.component';
import { EmptyComponent } from '../../empty/empty.component';
import { ButtonComponent } from '../../button/button.component';
import { UserService } from 'src/app/shared/services/user.service';
import { LeaderboardService } from 'src/app/shared/services/fan-area/leaderboard.service';
import { FloatingMenuService } from 'src/app/core/layout/floating-menu/floating-menu.service';
import { BUTTON_TYPE, COLOSSEUM_TIERS, FAN_AREA_ITEMS } from 'src/app/shared/enums';
import { Button } from 'src/app/shared/interfaces';

@Component({
  selector: 'leaderboard-section',
  templateUrl: './leaderboard-section.component.html',
  styleUrls: ['./leaderboard-section.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    NgOptimizedImage,
    NgxPaginationModule,
    HowToEarnPointsComponent,
    EmptyComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaderboardSectionComponent implements OnInit {
  public userInfo$ = this.userService.userInfo$;
  public leaderboardData$ = this.leaderboardService.leaderboardData$;
  public meLeaderboardData$ = this.leaderboardService.meLeaderboardData$;
  public leaderboardActivePeriodData$ = this.leaderboardService.leaderboardActivePeriodData$;

  public isMobile$ = this.breakpointObserver.observe(['(max-width: 768px)']).pipe(map((state) => state.matches));
  public inviteBtn: Button = {
    iconRight: 'icon-Plus',
    name: 'Invite friends',
    type: BUTTON_TYPE.DARK_FILLED
  };

  public COLOSSEUM_TIERS = COLOSSEUM_TIERS;
  public FAN_AREA_ITEMS = FAN_AREA_ITEMS;
  public paginationConfig$: BehaviorSubject<PaginationInstance> = new BehaviorSubject<PaginationInstance>({
    id: 'leaderboard',
    currentPage: 1,
    itemsPerPage: 10
  });
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public skeletonItems = Array.from({ length: 10 });

  constructor(
    private dialogService: MatDialog,
    private userService: UserService,
    private leaderboardService: LeaderboardService,
    private floatingMenuService: FloatingMenuService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit() {
    this.leaderboardService
      .getLeaderboard()
      .pipe(
        tap(({ data }) => {
          const pagination = data?.pagination;

          this.paginationConfig$.next({
            ...this.paginationConfig$.value,
            currentPage: pagination?.currentPage || 1,
            totalItems: pagination?.totalResults || 0
          });
        })
      )
      .subscribe();

    this.leaderboardService.getLeaderboardActivePeriod().subscribe();

    this.userService.userInfo$
      .pipe(
        filter((user) => !!user?.id),
        switchMap(({ id }) => this.leaderboardService.getLeaderboardById(id))
      )
      .subscribe();
  }

  openModal() {
    this.dialogService.open(LeaderboardInfoModalComponent, {
      panelClass: ['dialog-overlay-pane']
    });
  }

  openInviteFriends() {
    this.isMobile$
      .pipe(
        tap((isMobile) => {
          isMobile
            ? this.router.navigateByUrl('/fan-area/referrals')
            : this.floatingMenuService.openDrawer(FAN_AREA_ITEMS.REFERRALS);
        })
      )
      .subscribe();
  }

  onPageChange(page: number) {
    this.isLoading$.next(true);

    this.paginationConfig$.next({
      ...this.paginationConfig$.value,
      currentPage: page
    });
    this.leaderboardService
      .getLeaderboard(page, false)
      .pipe(tap(() => this.isLoading$.next(false)))
      .subscribe();
  }

  getPrizeShare(place: number) {
    const firstPlacePrize = 100_000;

    if (place === 1) {
      return firstPlacePrize;
    }

    if (place > 1 && place <= 10) {
      return firstPlacePrize - (place - 1) * 10_000;
    }

    return '-';
  }
}
