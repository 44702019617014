import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { USER_ROLES } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  private unverifiedAccessTokenSrc = new BehaviorSubject<string>('');
  private accessTokenSrc = new BehaviorSubject<string>('');
  private refreshTokenSrc = new BehaviorSubject<string>(localStorage.getItem('refreshToken') || '');
  private userRolesSrc = new BehaviorSubject<string>(localStorage.getItem('userRoles') || USER_ROLES.ROLE_USER);
  private managerTokenSrc = new BehaviorSubject<string>(localStorage.getItem('managerToken') || '');
  private utmSourceIdSrc = new BehaviorSubject<string>(localStorage.getItem('utmSourceId') || '');
  private invitingUserIdSrc = new BehaviorSubject<string>(localStorage.getItem('invitingUserId') || '');

  isLoggedIn$ = this.accessTokenSrc.pipe(map((token) => !!token));
  get isLoggedIn(): boolean {
    return !!this.accessTokenSrc.value;
  }

  constructor() {
    this.accessTokenSrc.next(localStorage.getItem('accessToken') || '');
  }

  // Observable getters

  getAccessToken$(): Observable<string> {
    return this.accessTokenSrc.asObservable();
  }

  getUnverifiedAccessToken$(): Observable<string> {
    return this.unverifiedAccessTokenSrc.asObservable();
  }

  getRefreshToken$(): Observable<string> {
    return this.refreshTokenSrc.asObservable();
  }

  getUserRoles$(): Observable<string> {
    return this.userRolesSrc.asObservable();
  }

  getManagerToken$(): Observable<string> {
    return this.managerTokenSrc.asObservable();
  }

  getUtmSourceId$(): Observable<string> {
    return this.utmSourceIdSrc.asObservable();
  }

  getInvitingUserId$(): Observable<string> {
    return this.invitingUserIdSrc.asObservable();
  }

  // Synchronous value getters
  get accessTokenSnapshot(): string {
    return this.accessTokenSrc.value;
  }

  get unverifiedAccessTokenSnapshot(): string {
    return this.unverifiedAccessTokenSrc.value;
  }

  get refreshTokenSnapshot(): string {
    return this.refreshTokenSrc.value;
  }

  get userRolesSnapshot(): string {
    return this.userRolesSrc.value;
  }

  get managerTokenSnapshot(): string {
    return this.managerTokenSrc.value;
  }

  get utmSourceIdSnapshot(): string {
    return this.utmSourceIdSrc.value;
  }

  get invitingUserIdSnapshot(): string {
    return this.invitingUserIdSrc.value;
  }

  // Setters
  setAccessToken(token: string): void {
    localStorage.setItem('accessToken', token);
    this.accessTokenSrc.next(token);
  }

  setUnverifiedAccessToken(token: string): void {
    this.unverifiedAccessTokenSrc.next(token);
  }

  setRefreshToken(token: string): void {
    localStorage.setItem('refreshToken', token);
    this.refreshTokenSrc.next(token);
  }

  setUserRoles(roles: string): void {
    localStorage.setItem('userRoles', roles);
    this.userRolesSrc.next(roles);
  }

  setManagerToken(token: string): void {
    localStorage.setItem('managerToken', token);
    this.managerTokenSrc.next(token);
  }

  setUtmSourceId(id: string): void {
    localStorage.setItem('utmSourceId', id);
    this.utmSourceIdSrc.next(id);
  }

  setInvitingUserId(id: string): void {
    localStorage.setItem('invitingUserId', id);
    this.invitingUserIdSrc.next(id);
  }

  clearData(): void {
    this.setAccessToken('');
    this.setRefreshToken('');
    this.setUserRoles(USER_ROLES.ROLE_USER);
    this.setManagerToken('');
    this.setUtmSourceId('');
    this.setInvitingUserId('');
    this.setUnverifiedAccessToken('');
  }
}
