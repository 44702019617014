import { trigger, transition, style, animate } from '@angular/animations';

export const smartWalletFiguresInOut = trigger('figuresFadeInOut', [
  transition(':enter', [
    style({ opacity: 0, scale: 0.9, rotate: '-10deg' }),
    animate('0.5s ease-out', style({ opacity: 1, scale: 1, rotate: '0deg' }))
  ]),
  transition(':leave', [
    style({ opacity: 1, scale: 1, rotate: '0deg' }),
    animate('0.5s ease-out', style({ opacity: 0, scale: 0.9, rotate: '-10deg' }))
  ])
]);

export const smartWalletFadeInOut = trigger('fadeInOut', [
  transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-out', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('0.5s ease-out', style({ opacity: 0 }))])
]);
