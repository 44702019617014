import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MeldService } from '../../../services/meld.service';
import { environment } from '../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from 'src/app/shared/pipes/safeUrl.pipe';
import { ToastrService } from 'ngx-toastr';

interface DialogData {
  address: string;
}

@Component({
  selector: 'meld-modal',
  templateUrl: './meld-modal.component.html',
  styleUrls: ['./meld-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, SafeUrlPipe, MatDialogModule],
  providers: [MeldService]
})
export class MeldModalComponent implements OnInit {
  urlSafe: string = '';
  destinationCurrencyCode = encodeURIComponent('GMRX_BSC');
  sourceCurrencyCode = encodeURIComponent('USD');
  transactionType = encodeURIComponent('BUY');
  countryCode = encodeURIComponent('US');

  private address: string = this.dialogData?.address;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private dialogRef: MatDialogRef<MeldModalComponent>,
    private meldService: MeldService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    if (!this.address) {
      this.dialogRef.close();
      this.toastrService.error('Something went wrong, please try again later');
    }

    this.meldService.getCryptoWizardDetailsRequest().subscribe({
      next: (response) => {
        if (response.success) {
          const { publicKey, userId } = response.data!;

          const urlParams = new URLSearchParams({
            publicKey: publicKey,
            countryCode: this.countryCode,
            sourceCurrencyCode: this.sourceCurrencyCode,
            destinationCurrencyCode: this.destinationCurrencyCode,
            walletAddress: this.address,
            externalCustomerId: userId.toString(),
            transactionType: this.transactionType
          });

          this.urlSafe = `${environment.meldLink}/?${urlParams.toString()}`;
        }
      },
      error: () => {
        this.closeModal();
      }
    });
  }

  public closeModal() {
    this.dialogRef.close();
  }
}
