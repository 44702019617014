import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, skip, tap, finalize } from 'rxjs';
import { UpdateGaiminAccountModalComponent } from '../components/modals/update-gaimin-account-modal/update-gaimin-account-modal.component';
import { AuthService } from './auth.service';
import { WalletConnectService } from './wallet-connect.service';
import { environment } from '../../../environments/environment';
import {
  ExchangeRateData,
  Response,
  SubscriptionTypeData,
  UserAvatar,
  UserInfo,
  PlayerConversation
} from '../interfaces';
import { COLOSSEUM_TIERS, EXCHANGE_CURRENCY, USER_VERIFICATION_STATUS } from '../enums';
import { SHOW_LOADER } from '../../core/interceptors/loader.interceptor';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userInfo$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>({} as UserInfo);
  userSubscriptionData$: BehaviorSubject<SubscriptionTypeData> = new BehaviorSubject<SubscriptionTypeData>(
    {} as SubscriptionTypeData
  );

  subscriptionTypesList: {
    [key in COLOSSEUM_TIERS]: SubscriptionTypeData;
  } = {
    [COLOSSEUM_TIERS.BRONZE]: { name: 'Bronze', img: null },
    [COLOSSEUM_TIERS.SILVER]: {
      name: 'Silver',
      img: 'assets/icons/packages/silver.png'
    },
    [COLOSSEUM_TIERS.GOLD]: { name: 'Gold', img: 'assets/icons/packages/gold.png' },
    [COLOSSEUM_TIERS.PLATINUM]: {
      name: 'Platinum',
      img: 'assets/icons/packages/platinum.png'
    }
  };

  get USER_VERIFICATION_STATUS(): typeof USER_VERIFICATION_STATUS {
    return USER_VERIFICATION_STATUS;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private dialogService: MatDialog,
    private authService: AuthService,
    private walletConnectService: WalletConnectService
  ) {
    this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          const currentRoute: ActivatedRouteSnapshot = this.router.routerState.snapshot.root;
          const queryParams = currentRoute.queryParams;

          if (queryParams['type'] === 'migrate' && queryParams['accessToken']) {
            const accessToken = queryParams['accessToken'];

            this.authService
              .userMigrationRequest({ gaiminToken: 'Bearer ' + accessToken })
              .pipe(
                tap((res) => {
                  if (res.success) {
                    this.location.replaceState('/content');
                  }
                }),
                finalize(() => this.handleUserInfoRequest())
              )
              .subscribe();
          } else {
            this.handleUserInfoRequest();
          }

          this.walletConnectService.setActiveWallet();
          this.authService.updateSubscriptionInfo();
        } else {
          this.userInfo$.next({} as UserInfo);
        }
      }
    });

    this.authService.acquireUserSubscriptionData$.pipe(skip(1)).subscribe({
      next: (response) => {
        if (!response?.tier) return;

        this.userSubscriptionData$.next({
          ...this.subscriptionTypesList[response.tier],
          days: response?.paidSubTotalDays,
          type: response?.tier
        });
      }
    });
  }

  private handleUserInfoRequest() {
    this.getUserInfoRequest().subscribe((response) => {
      if (response.success && response.data) {
        this.userInfo$.next(response.data);

        if (!response.data.gaiminId) {
          this.showUpdateGaiminAccountPopup();
        }
      }
    });
  }

  isAccountActive() {
    return this.userInfo$.getValue()?.colosseumTrial?.colosseumTrialStatus !== USER_VERIFICATION_STATUS.EXPIRED;
  }

  showUpdateGaiminAccountPopup() {
    this.dialogService.open(UpdateGaiminAccountModalComponent, {
      panelClass: ['dialog-overlay-pane'],
      disableClose: true
    });
  }

  private getUserInfoRequest(): Observable<Response<UserInfo>> {
    return this.http.get<any>(environment.gaiminApi + '/users/me');
  }

  updateUserInfo(data: UserInfo): Observable<Response<UserInfo>> {
    return this.http.patch<Response<UserInfo>>(environment.gaiminApi + '/users/me', data);
  }

  updateUserAvatar(data: UserAvatar): Observable<Response<UserInfo>> {
    return this.http.patch<Response<UserInfo>>(`${environment.gaiminApi}/users/me`, data);
  }

  uploadAvatarImage(formData: any): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${environment.gaiminApi}/images`, formData);
  }

  getExchangeRateRequest(currencies: EXCHANGE_CURRENCY[]): Observable<Response<ExchangeRateData>> {
    return this.http.post<Response<ExchangeRateData>>(`${environment.gaiminApi}/exchange-rate`, {
      currencies: currencies
    });
  }

  getDiscordToken(code: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('skipAuth', 'true');
    return this.http.post<any>(
      'https://discord.com/api/oauth2/token',
      new URLSearchParams({
        client_id: environment.discordClientId,
        client_secret: environment.discordClientSecret,
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: environment.discordRedirectUri,
        scope: 'identify'
      }),
      {
        headers
      }
    );
  }

  getDiscordUserData(access_token: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${access_token}`).set('skipAuth', 'true');
    return this.http.get<any>('https://discord.com/api/users/@me', { headers });
  }

  resendConfirmEmailRequest(email: string) {
    return this.http.get(environment.gaiminApi + `/users/register/confirm/resend-email?email=${email}`, {
      responseType: 'text'
    });
  }

  sendUserActivityRequest(): Observable<Response<any>> {
    return this.http.post<Response<any>>(`${environment.gaiminApi}/users/activity`, {});
  }

  sendMessageToPlayerRequst(message: string, playerId: number): Observable<Response<PlayerConversation>> {
    return this.http.post<Response<PlayerConversation>>(
      `${environment.gaiminApi}/players/${playerId}/profiles/messages`,
      { message: message }
    );
  }

  addToMailingList() {
    return this.http.post(`${environment.gaiminApi}/users/mailing-list`, null, {
      context: new HttpContext().set(SHOW_LOADER, false)
    });
  }
}
